
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Row, Col, Button, Input, Card, CardBody } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../../assets/images/users/user-4.jpg';
import moment from 'moment';
import { URL } from '../../variables';
import servicesIcon1 from "../../assets/images/services-icon/01.png";
import servicesIcon2 from "../../assets/images/services-icon/02.png";
import servicesIcon3 from "../../assets/images/services-icon/03.png";
import servicesIcon4 from "../../assets/images/services-icon/04.png";
import { maskPrice } from '../Auxiliar/Masks';

import LineAreaChart from "../../AllCharts/apex/lineareachart";
class MenuItens extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            nao_iniciado: false,
            regras_processo: false,
            andamento: false,
            finalizada: false,
            loading_screen: true
        };
    }





    componentDidMount() {
        this.props.mudarTitle('');
    }




    render() {
        return (
            <div>

                <div className="row">
                    {this.state.redirect == true && <Redirect to={this.state.path} />}


                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <Link to="/empresas"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                            <i className="fas fa-store-alt" style={{ fontSize: '1.8rem', marginRight: '1rem', color: '#1893d6' }}></i>
                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Empresas</p>
                        </div></Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <Link to="/chamados"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                            <i className="mdi mdi-message-text-outline" style={{ fontSize: '1.8rem', marginRight: '1rem', color: '#1893d6' }}></i>
                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Chamados</p>
                        </div></Link>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <Link to="/avisos"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                            <i className="mdi mdi-bell" style={{ fontSize: '1.8rem', marginRight: '1rem', color: '#1893d6' }}></i>
                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Notificações</p>
                        </div></Link>
                    </div>

                </div>
                <hr />
                <div className="page-title-box">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <h4 style={{ color: 'black', textAlign: 'center',fontSize:'1.75rem' }}>Dashboard</h4>
                        </div>
                    </div>
                </div>
                <Row style={{ marginTop: '1rem' }}>
                    <Col xl={3} md={6}>
                        <Card className="mini-stat bg-primary text-white">
                            <CardBody>
                                <div className="mb-4">
                                    <div className="float-left mini-stat-img mr-4">
                                        <img src={servicesIcon1} alt="" />
                                    </div>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white">
                                        Vendas
                  </h5>
                                    <h4 className="font-weight-medium font-size-24">
                                        {this.props.num_vendas + " "}
                                        {/* <i className="mdi mdi-arrow-up text-success ml-2"></i> */}
                                    </h4>
                                    {/* <div className="mini-stat-label bg-success">
                                      <p className="mb-0">+ 12%</p>
                                  </div> */}
                                </div>
                                <div className="pt-2">
                                    <div className="float-right">
                                        <Link to="/empresas" className="text-white">
                                            <i className="mdi mdi-arrow-right h5"></i>
                                        </Link>
                                    </div>
                                    <p className="text-white mb-0 mt-1">Desde o Mês Passado</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={3} md={6}>
                        <Card className="mini-stat bg-primary text-white">
                            <CardBody>
                                <div className="mb-4">
                                    <div className="float-left mini-stat-img mr-4">
                                        <img src={servicesIcon2} alt="" />
                                    </div>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white">
                                        Simulações
                  </h5>
                                    <h4 className="font-weight-medium font-size-24">
                                        {this.props.num_vendas_sim + " "}
                                        {/* <i className="mdi mdi-arrow-down text-danger ml-2"></i> */}
                                    </h4>
                                    {/* <div className="mini-stat-label bg-danger">
                                      <p className="mb-0">- 28%</p>
                                  </div> */}
                                </div>
                                <div className="pt-2">
                                    <div className="float-right">
                                        <Link to="/empresas" className="text-white">
                                            <i className="mdi mdi-arrow-right h5"></i>
                                        </Link>
                                    </div>

                                    <p className="text-white mb-0 mt-1">Desde o Mês Passado</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={3} md={6}>
                        <Card className="mini-stat bg-primary text-white">
                            <CardBody>
                                <div className="mb-4">
                                    <div className="float-left mini-stat-img mr-4">
                                        <img src={servicesIcon4} alt="" />
                                    </div>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white">
                                        Produtos
                                  </h5>
                                    <h4 className="font-weight-medium font-size-24">
                                        {this.props.num_produtos + " "}
                                        {/* <i className="mdi mdi-arrow-up text-success ml-2"></i> */}
                                    </h4>
                                    {/* <div className="mini-stat-label bg-warning">
                                      <p className="mb-0">+ 84%</p>
                                  </div> */}
                                </div>
                                <div className="pt-2">
                                    {/* <div className="float-right">
                                      <Link to="#" className="text-white">
                                          <i className="mdi mdi-arrow-right h5"></i>
                                      </Link>
                                  </div> */}
                                    <p className="text-white mb-0 mt-1">Total</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl={3} md={6}>
                        <Card className="mini-stat bg-primary text-white">
                            <CardBody>
                                <div className="mb-4">
                                    <div className="float-left mini-stat-img mr-4">
                                        <img src={servicesIcon3} alt="" />
                                    </div>
                                    <h5 className="font-size-16 text-uppercase mt-0 text-white">
                                        Total Vendido
                                  </h5>
                                    <h4 className="font-weight-medium font-size-24">
                                        {maskPrice(parseFloat(this.props.total_mes).toFixed(2)) + " "}
                                        {/* <i className="mdi mdi-arrow-up text-success ml-2"></i> */}
                                    </h4>
                                    {/* <div className="mini-stat-label bg-info">
                                      <p className="mb-0"> 00%</p>
                                  </div> */}
                                </div>
                                <div className="pt-2">
                                    {/* <div className="float-right">
                                      <Link to="#" className="text-white">
                                          <i className="mdi mdi-arrow-right h5"></i>
                                      </Link>
                                  </div> */}

                                    <p className="text-white mb-0 mt-1">Desde o Mês Passado</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                   
                </Row>
                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Vendas Totais Mensais</h4>
                                <Row>
                                    <Col lg={7}>
                                        <div>
                                            <LineAreaChart values={this.props.total_meses} />
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="text-center">
                                                    <p className="text-muted mb-4">Este mês</p>
                                                    {Object.values(this.props.total_meses).length > 0 && <h3>{maskPrice(parseFloat(Object.values(this.props.total_meses)[Object.values(this.props.total_meses).length - 1]).toFixed(2))}</h3>}
                                                    {/* <p className="text-muted mb-5">
                                                      It will be as simple as in fact it will be
                                                      occidental.
                                                  </p> */}
                                                    {/* <RadialChart total_meses={Object.values(this.props.total_meses)} /> */}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="text-center">
                                                    <p className="text-muted mb-4">Mês passado</p>
                                                    {Object.values(this.props.total_meses).length > 0 && <h3>{maskPrice(parseFloat(Object.values(this.props.total_meses)[Object.values(this.props.total_meses).length - 2]).toFixed(2))}</h3>}
                                                    {/* <p className="text-muted mb-5">
                                                      It will be as simple as in fact it will be
                                                      occidental.
                                                  </p>
                                                  <Apexdonut /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col xl={12}>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Top 10 produtos que mais vendem (Ordem pelo valor total)</h4>
                                <div className="table-responsive">
                                    <table className="table table-hover table-centered table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">(#) Id</th> */}
                                                <th scope="col">Nome</th>
                                                <th scope="col">Quantidade Total</th>
                                                <th scope="col">Valor Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.produtos.map((item, id) => (

                                                <tr key={id}>
                                                    {/* <th scope="row">#{item.id}</th> */}
                                                    <td>
                                                        <div>
                                                            <img
                                                                src={URL + item.imagem}
                                                                alt=""
                                                                style={{width:35,height:35, objectFit:'cover',border:'1px solid lightgrey'}}
                                                                className="avatar-xs rounded-circle mr-2"
                                                            />{" "}
                                                            {item.nome}
                                                        </div>
                                                    </td>
                                                    <td>{item.quantidade}</td>
                                                    <td>
                                                        {maskPrice(parseFloat(item.valor_total).toFixed(2))}
                                                    </td>

                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                    {this.props.produtos.length == 0 && <div className="row w-100 mt-4">
                                        <div className="col-12">
                                            <p style={{ textAlign: 'center' }}>Nenhuma venda de produto registrada</p>

                                        </div>
                                    </div>}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(MenuItens);


