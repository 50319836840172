
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';

class Informacoes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            clientes: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            produto_to_delete: '',
            loja: {},
        };
    }






    componentDidMount() {
        this.get_infos(this.props.loja_id);
    }

    get_infos(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_infos/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ clientes: resp.clientes,loja:resp.loja, loading_screen: false });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
            <br/>


                    {/* <hr /> */}
                    {this.state.loading_screen == false && <div>
                        <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black',textAlign:'center' }}>Clientes Cadastrados</h4>
                        <div className="row mb-2">
                            {/* <div className="col-12 col-md-6">

                            </div>

                            <div className="col-12 col-md-6 d-flex justify-content-end">
                                <Link to={"/loja/produtos/criar/" + this.state.loja.id}><span
                                    className="btn btn-primary btn-lg waves-effect waves-light" style={{ height: '45px' }}>Adicionar produtos</span></Link>
                            </div> */}
                        </div>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>

                                            <th>Nome</th>
                                            <th>Email</th>
                                            {/* <th  >Preço Normal</th> */}
                                            {/* <th  >Ações</th> */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.clientes.map((item, id) => (

                                            <tr key={id}>

                                                <th>{item.name}</th>
                                                <td>{item.email}</td>

                                                {/* <td>{maskPrice(item.preco_antes)}</td> */}

                                                {/* <td>
                                                   
                                                    <Link to={'/loja/produtos/editar/' + item.id}><span className="btn btn-info btn-outline btn-circle m-r-5"
                                                        data-toggle="tooltip"
                                                    ><i className="ti-pencil-alt"></i></span></Link>
                                                    <button onClick={() => {
                                                        this.setState({ show_warning: true, produto_to_delete: item.id });
                                                    }}
                                                        className="destroy_it btn btn-danger btn-outline btn-circle m-r-5"><i
                                                            className="ti-trash"></i></button>
                                                </td> */}

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.state.clientes.length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhum cliente cadastrado</p>

                            </div>
                        </div>}


                        {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                    </div>}

                    {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div>}
                   

                </div>

            </div>
        );
    }

    
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(Informacoes));


