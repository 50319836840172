
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../../variables';
import ReactPaginate from 'react-paginate';
import { maskPrice } from '../../../Auxiliar/Masks';

class AdicionarGerente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/ver_empresa/',
            loading_screen: true,
            nome: '',
            loading_save: false,
            msgErro: '',
            preco: '',
            descricao: '',
            estoque: '',
            imagem: '',
            comissao: '',
            empresa: {},
            fotos: [],
            fotos_path: [],
            tipo:'existente',

            gerente_name: '',
            msgErro: '',
            loading_search_gerente: false,
            gerentes: [],
            gerentes_selecionados: {},
            show_autocomplete_gerente: false,
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });

        fetch(`${URL}api/minha_empresa/salvar_gerente`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify({
                empresa_id: this.state.empresa.id,
                gerentes: Object.keys(this.state.gerentes_selecionados),
                tipo:this.state.tipo,
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.confirm_password,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro }, () => {
                        window.scrollTo(0, 0);

                    });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_empresa(this.props.match.params.id);
    }

    get_empresa(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/minha_empresa/get_empresa/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_screen: false, empresa: resp.empresa, });
                    // if (resp.processo != null) {
                    this.props.mudarTitle('Gerentes - ' + resp.empresa.nome);


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    select_gerente(item) {
        let users = { ...this.state.gerentes_selecionados };
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ gerente_name: '', show_autocomplete_gerente: false, gerentes_selecionados: users })
    }

    get_gerente_search(search) {

        if (this.state.loading_search_dono == false) {
            this.setState({ loading_search_dono: true });
        }
        fetch(`${URL}api/gerentes_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_gerente: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_gerente: false, gerentes: resp.users });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_gerente: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_gerente: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path + this.props.match.params.id} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Adicionar Gerentes</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Adicione os gerentes da empresa {this.state.empresa.nome}</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Escolha uma opção <b style={{ color: 'red' }}>*</b></label>

                                    <div className="col-sm-12">
                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                        <select value={this.state.tipo} className="custom-select" id="cameras" onChange={(e)=>{
                                            this.setState({tipo:e.target.value});
                                        }}>
                                            <option value={'existente'}>Selecionar um ou mais gerentes existentes</option>
                                            <option value={'criar'}>Criar uma conta para um novo gerente</option>

                                        </select>
                                    </div>
                                </div>

                                <div className="row" hidden={this.state.tipo!='existente'}>

                                    <div className="col-12">

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Gerente(s)</label>
                                            <div className="col-sm-12">
                                                <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_gerente: true })} className="form-control" value={this.state.gerente_name} placeholder="Nome do gerente da empresa" type="text" onChange={(e) => { this.get_gerente_search(e.target.value); this.setState({ gerente_name: e.target.value }) }} />
                                                {this.state.show_autocomplete_gerente == true && this.state.gerente_name != '' && <div className="overflow-auto" style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto' }}>
                                                    {this.state.loading_search_gerente == false && this.state.gerentes.map((item, id) => (
                                                        <div key={id} onClick={() => this.select_gerente(item)} className="opcao_autocomplete">
                                                            {item.name} - {item.email}
                                                        </div>
                                                    ))}
                                                    {this.state.loading_search_gerente == false && this.state.gerentes.length == 0 &&
                                                        <div className="opcao_autocomplete">
                                                            Nenhum gerente encontrado com este termo
                                                        </div>
                                                    }
                                                    {this.state.loading_search_gerente == true &&
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <div className="spinner-border text-primary" role="status"  >
                                                                <span className="sr-only">Loading...</span>
                                                            </div></div>}
                                                </div>}
                                            </div>


                                        </div>

                                        {Object.values(this.state.gerentes_selecionados).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Gerente(s) Selecionado(s):</label>

                                            {Object.values(this.state.gerentes_selecionados).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-6 col-lg-4" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{item.name}<br />{item.email}</span>
                                                    <button onClick={() => {
                                                        let users = { ...this.state.gerentes_selecionados };
                                                        delete (users[item.id]);
                                                        this.setState({ gerentes_selecionados: users });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                            className="ti-trash" style={{ color: 'red' }}></i></button>
                                                </div>
                                            ))}

                                        </div>}
                                    </div>

                                </div>

                                <div className="row" hidden={this.state.tipo!='criar'}>
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do gerente" type="text" onChange={(e) => this.setState({ name: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>E-mail <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="E-mail" type="text" onChange={(e) => this.setState({ email: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Senha <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Senha" type="password" onChange={(e) => this.setState({ password: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Confirmar Senha <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Confirmar Senha" type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to={"/ver_empresa/" + this.state.empresa.id}> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar gerente(s)</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(AdicionarGerente);


