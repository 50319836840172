import React, { Component } from 'react';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { URL } from '../../../variables';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import Modal from 'react-bootstrap/Modal';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        // overflowY: 'scroll'
    }
};
class ChatVendedor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conversas: [],
            conversas_ids: [],

            loading: true,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            vendedor_to_delete: '',
            conversa_select: '',
            vendedor_select: '',
            protocolo_select: '',
            mensagem: '',
            empresa_id: null,
            empresa: {},
            modal_open: false,
            msgErroResposta: '',
            assunto: '',
            mensagem: '',
            loading_save: false,

            vendedor_name: '',
            loading_search_vendedor: false,
            vendedors: [],
            vendedor_selecionado: {},
            show_autocomplete_vendedor: false,

            assunto: '',
            mensagem_inicial: '',
            name_search: '',

            loading_finalizar: false,
            msgErroJustificativa: '',
            justificativa: '',
            modal_open_finalizar: false,
            show_sucesso: false

        };
        this.messages_view = React.createRef();

    }


    get_conversas(empresa_id) {
        this.setState({ loading: true });

        fetch(`${URL}api/minha_empresa/get_conversas_vendedor?empresa_id=${empresa_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.setState({
                        conversas: resp.conversas,
                        conversas_ids: resp.conversas_ids,
                        empresa: resp.empresa
                    }, () => {
                        this.init_shared_worker();
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentWillUnmount() {
        if (this.shared_worker != null) {
            this.shared_worker.terminate();
            this.shared_worker = null;
        }
    }

    componentDidMount() {
        this.props.mudarTitle('Chamados em Aberto');
        this.setState({ empresa_id: this.props.empresa_id }, () => {
            this.get_conversas(this.state.empresa_id);
        })
    }

    init_shared_worker() {
        this.shared_worker = new Worker('socket_atendente.js');

        this.shared_worker.onmessage = (e) => {
            console.log(e.data);
            let conversas_ids = this.state.conversas_ids;
            let conversas = this.state.conversas;
            console.log(conversas_ids);
            if (e.data.status == 'connect') {
                this.shared_worker.postMessage({ empresa_id: this.state.empresa_id, status: 'init', atendente_name: this.props.user.name, atendente_id: this.props.user.id })
                this.setState({ loading: false });
            }
            if (e.data.empresa_id == this.state.empresa_id) {

                if (e.data.conversa_id != null && !this.state.conversas_ids.includes(parseInt(e.data.conversa_id))) {

                    conversas_ids.push(parseInt(e.data.conversa_id));
                    conversas.unshift({
                        id: e.data.conversa_id,
                        vendedor_id: e.data.vendedor_id,
                        vendedor: (e.data.vendedor_id != null && e.data.vendedor_id != '') ? { id: e.data.vendedor_id, name: e.data.vendedor_name, foto: e.data.vendedor_foto } : null,
                        vendedor_name: e.data.vendedor_name,
                        vendedor_uuid: e.data.vendedor_uuid,
                        mensagens: [],
                        empresa_id: e.data.empresa_id,
                        protocolo: e.data.protocolo,
                        last_message: '',
                        nao_vistas: 0,
                        created_at:moment().format('YYYY-MM-DD HH:mm:ss'),
                        assunto: e.data.assunto
                    });
                    this.setState({ conversas, conversas_ids }, () => {
                        if (this.state.conversa_select == e.data.conversa_id) {
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                    });

                }

                if (e.data.status == 'chamado_user_criado') {
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversas[i].id == e.data.conversa_id) {
                            if (e.data.atendente_id == this.props.user.id) {
                                conversas[i].mensagens.push({
                                    atendente: this.props.user,
                                    atendente_id: this.props.user.id,
                                    message: e.data.msg,
                                    uuid: e.data.id_msg,
                                    conversa_id: e.data.conversa_id,
                                    visto: false,
                        created_at:moment().format('YYYY-MM-DD HH:mm:ss'),

                                });
                                this.setState({ modal_open: false, loading_save: false });
                            }
                            else {
                                conversas[i].mensagens.push({
                                    atendente: { id: e.data.atendente_id, name: e.data.atendente_name },
                                    atendente_id: e.data.atendente_id,
                                    message: e.data.msg,
                                    uuid: e.data.id_msg,
                                    empresa_id: e.data.empresa_id,
                                    created_at:moment().format('YYYY-MM-DD HH:mm:ss'),

                                    conversa_id: e.data.conversa_id
                                });
                                // conversas[i].last_message = e.data.atendente_name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '')
                            }
                            conversas[i].nao_vistas = 0;

                            conversas[i].last_message = e.data.atendente_name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '')
                            break;
                        }
                    }


                    this.shared_worker.postMessage({ empresa_id: this.state.empresa_id, status: 'visto', conversa_id: e.data.conversa_id, vendedor_id: e.data.vendedor_id == null ? null : e.data.vendedor_id, vendedor_uuid: e.data.vendedor_uuid, protocolo: e.data.protocolo });
                    this.setState({ conversas, conversas_ids, conversa_select: e.data.conversa_id, vendedor_select: e.data.vendedor_id, protocolo_select: e.data.protocolo }, () => {
                        if (this.state.conversa_select == e.data.conversa_id) {
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                    });

                }

                else if (e.data.status == 'msg_save') {
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversas[i].id == e.data.conversa_id) {
                            for (let j = 0; j < conversas[i].mensagens.length; j++) {
                                if (conversas[i].mensagens[j].uuid == e.data.id_msg) {
                                    conversas[i].mensagens[j].visto = false;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    this.setState({ conversas, conversas_ids }, () => {
                        if (this.state.conversa_select == e.data.conversa_id) {
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                    });

                }
                else if (e.data.status == 'message_other_tab') {
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversas[i].id == e.data.conversa_id) {
                            conversas[i].mensagens.push({
                                atendente: this.props.user,
                                atendente_id: this.props.user.id,
                                message: e.data.msg,
                                uuid: e.data.id_msg,
                                empresa_id: e.data.empresa_id,
                                created_at:moment().format('YYYY-MM-DD HH:mm:ss'),

                                conversa_id: e.data.conversa_id
                            })
                            conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '')
                        }
                    }
                    this.setState({ conversas, conversas_ids }, () => {
                        if (this.state.conversa_select == e.data.conversa_id) {
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                    });

                }
                else if (e.data.status == 'msg_receive') {
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversas[i].id == e.data.conversa_id) {
                            conversas[i].mensagens.push({
                                atendente: null,
                                atendente_id: null,
                                message: e.data.msg,
                                uuid: e.data.id_msg,
                                empresa_id: e.data.empresa_id,
                                created_at:moment().format('YYYY-MM-DD HH:mm:ss'),

                                conversa_id: e.data.conversa_id,
                                vendedor: e.data.vendedor_id == null ? null : { id: e.data.vendedor_id, name: e.data.vendedor_name },
                                vendedor_name: e.data.vendedor_name,
                                vendedor_id: e.data.vendedor_id,
                                vendedor_uuid: e.data.vendedor_uuid,
                            });
                            console.log('Não vista');
                            console.log(this.state.conversa_select);

                            if (this.state.conversa_select != e.data.conversa_id) {
                                conversas[i].nao_vistas += 1;
                                console.log('Não vista');

                                console.log(conversas[i].nao_vistas);

                            }
                            else {
                                this.shared_worker.postMessage({ protocolo: conversas[i].protocolo, empresa_id: this.state.empresa_id, status: 'visto', conversa_id: e.data.conversa_id, vendedor_id: e.data.vendedor_id == null ? null : e.data.vendedor_id, vendedor_uuid: e.data.vendedor_uuid, id_msg: e.data.id_msg })
                            }
                            conversas[i].last_message = e.data.vendedor_name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '');
                            let conversa_element = { ...conversas[i] };
                            conversas = conversas.filter((item, id) => item.id != conversa_element.id);
                            conversas.unshift(conversa_element);
                            break;
                        }
                    }
                    this.setState({ conversas, conversas_ids }, () => {
                        if (this.state.conversa_select == e.data.conversa_id) {
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                    });

                }
                else if (e.data.status == 'msg_receive_atendente') {
                    if (this.props.user.id != e.data.atendente_id) {
                        for (let i = 0; i < conversas.length; i++) {
                            if (conversas[i].id == e.data.conversa_id) {
                                conversas[i].mensagens.push({
                                    atendente: { id: e.data.atendente_id, name: e.data.atendente_name },
                                    atendente_id: e.data.atendente_id,
                                    message: e.data.msg,
                                    uuid: e.data.id_msg,
                                    empresa_id: e.data.empresa_id,
                                    created_at:moment().format('YYYY-MM-DD HH:mm:ss'),

                                    conversa_id: e.data.conversa_id
                                })
                                conversas[i].last_message = e.data.atendente_name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '')
                                let conversa_element = { ...conversas[i] };
                                conversas = conversas.filter((item, id) => item.id != conversa_element.id);
                                conversas.unshift(conversa_element);
                            }
                        }
                    }
                    this.setState({ conversas, conversas_ids }, () => {
                        if (this.state.conversa_select == e.data.conversa_id) {
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                    });

                }
                else if (e.data.status == 'msg_vista') {
                    let achou = false;
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversas[i].id == e.data.conversa_id) {
                            for (let j = 0; j < conversas[i].mensagens.length; j++) {
                                conversas[i].mensagens[j].visto = true;

                                if (conversas[i].mensagens[j].uuid == e.data.id_msg) {
                                    break;
                                }
                            }
                            break;

                        }
                    }
                    this.setState({ conversas, conversas_ids }, () => {
                        if (this.state.conversa_select == e.data.conversa_id) {
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                    });

                }

                else if (e.data.status == 'finalizar_chamado') {

                    conversas = conversas.filter((item, id) => {
                        return item.id != e.data.conversa_id;
                    });
                    this.setState({ conversa_select: '', vendedor_select: '', protocolo_select: '', conversas, conversas_ids, show_sucesso: true, loading_finalizar: false, modal_open_finalizar: false, justificativa: '' });


                }
            }

        }

    }


    get_vendedor_search(search) {

        if (this.state.loading_search_vendedor == false) {
            this.setState({ loading_search_vendedor: true });
        }
        fetch(`${URL}api/vendedor_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_vendedor: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_vendedor: false, vendedors: resp.users });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_vendedor: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_vendedor: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    render() {
        let conversas = this.state.conversas;
        if (this.state.name_search.trim().length > 0) {
            conversas = conversas.filter((item, id) => {
                return item.vendedor_name.toLowerCase().includes(this.state.name_search.toLowerCase()) || item.protocolo.toLowerCase().includes(this.state.name_search.toLowerCase());
            });
        }
        return (
            <div>

                <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>

                    <div style={{
                        margin: 15,
                        position: 'relative',
                        width: '100%',
                        marginTop: 0
                    }} >
                        {/* <h6>Chat de Atendimento</h6> */}
                        {(conversas.length > 0 || this.state.name_search != '') && <div className="row" style={{ borderRadius: 10, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.03)' }}>
                            <div className="col-md-4 overflow-auto"
                                style={{ position: 'relative', border: '1px solid white', padding: 0, height: '680px', background: 'white', borderRight: '1px solid lightgrey', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>

                                <div id="conversas_head">
                                    <div style={{ position: 'relative', padding: 10, height: 60 }}>
                                        <div style={{ position: 'absolute', top: 21, left: 20 }}>
                                            <i className="fas fa-search" style={{ fontSize: '1rem' }}></i>
                                        </div>
                                        <input autoComplete="off" onChange={(e) => this.setState({ name_search: e.target.value, conversa_select: '', vendedor_select: '', protocolo_select: '' })} value={this.state.name_search} name="msg" placeholder="Nome do vendedor ou Protocolo" className="form-control msg_input" style={{ resize: 'none', height: '100%', borderRadius: 50, paddingLeft: 40 }} />
                                    </div>
                                    {conversas.map((item, id) => (

                                        <div onClick={() => {
                                            let conversas = [...this.state.conversas];
                                            conversas[id].nao_vistas = 0;
                                            this.setState({ conversa_select: item.id, conversas, vendedor_select: item.vendedor_id, protocolo_select: item.protocolo }, () => {
                                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                            });

                                            this.shared_worker.postMessage({ empresa_id: this.state.empresa_id, status: 'visto', conversa_id: item.id, vendedor_id: item.vendedor_id == null ? null : item.vendedor_id, vendedor_uuid: item.vendedor_uuid, protocolo: item.protocolo });

                                        }} key={id} className="chat"
                                            style={{ background: this.state.conversa_select == item.id ? '#f2f2f2' : 'white', borderBottom: '1px solid grey', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', margin: '5px' }} >
                                                <div className="image mr-3"><img
                                                    src={(item.vendedor == null || item.vendedor.foto == null) ? URL + 'images/default_profile.jpg' : URL + 'storage/' + item.vendedor.foto}
                                                    alt="User image" className="rounded-circle" style={{ minWidth: '70px', height: '70px', width: '70px', objectFit: 'cover', border: '1px solid lightgrey' }} /></div>
                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: '1rem', color: 'black', marginBottom: '5px' }}>{item.vendedor == null ? item.vendedor_name : item.vendedor.name}</p>
                                                    <span style={{ fontWeight: 'normal', fontSize: '0.9rem' }}>
                                                        {item.last_message}
                                                    </span>

                                                </div>
                                            </div>
                                            <div style={{ marginRight: 5 }}>
                                                {item.mensagens.length > 0 && moment(item.mensagens[item.mensagens.length - 1].created_at).format('DDMMYYYY') != moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(item.mensagens[item.mensagens.length - 1].created_at).format('DD/MM/YYYY')}</p>}
                                                {item.mensagens.length > 0 && moment(item.mensagens[item.mensagens.length - 1].created_at).format('DDMMYYYY') == moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(item.mensagens[item.mensagens.length - 1].created_at).format('HH:mm')}</p>}
                                                {item.mensagens.length == 0 && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment().format('HH:mm')}</p>}
                                                <p style={{ color: "green", marginBottom: 0, textAlign: 'end' }}>{item.protocolo}</p>
                                                <div style={{ display: 'flex', margin: '5px', justifyContent: 'center', alignItems: 'center' }} >
                                                    <div hidden={item.nao_vistas == 0}
                                                        style={{ minWidth: '30px', backgroundColor: 'green', borderRadius: '100px', height: '30px', width: '30px', color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                        {item.nao_vistas}</div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}

                                </div>
                                <div style={{ position: 'absolute', bottom: 50, right: 20 }}>

                                    <button onClick={() => {
                                        this.setState({ modal_open: true, msgErroResposta: '', assunto: '', mensagem_inicial: '' });
                                    }} className="btn btn-success" style={{ borderRadius: 100 }}><i className="mdi mdi-message-text-outline"
                                        style={{ fontSize: '25px', color: 'white', fontStyle: 'normal' }}></i></button>
                                </div>
                            </div>
                            <div className="col-md-8 col-12" style={{ background: 'white', padding: 0, borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>

                                <div style={{ height: '630px', width: '100%' }} className="overflow-auto" id="body_msg" ref={this.messages_view}>

                                    {conversas.map((item, id) => (


                                        <div key={id} hidden={this.state.conversa_select != item.id}>
                                            <div style={{ borderBottom: '1px solid lightgrey', padding: 10 }}>
                                                        <p style={{ marginBottom: 0, textAlign: 'center', fontWeight: 'bold', fontSize: '0.93rem', color: 'black' }}>Assunto: <span style={{ fontWeight: 'normal', marginBottom: 0 }}>{item.assunto}</span></p>
                                                    </div>


                                            {item.mensagens.map((msg, id2) => (
                                                <div key={id2}>
                                                    {(id2 == 0 || moment(item.mensagens[id2].created_at).format('YYYY-MM-DD') != moment(item.mensagens[id2 - 1].created_at).format('YYYY-MM-DD')) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p style={{
                                                            marginTop: 10,
                                                            textAlign: 'center',
                                                            marginBottom: 0,
                                                            padding: '5px',
                                                            border: '1px solid lightslategrey',
                                                            color: 'darkslategrey',
                                                            borderRadius: '10px',
                                                            backgroundColor: 'aliceblue',
                                                            marginTop: '10px',
                                                            paddingLeft: '10px',
                                                            paddingRight: '10px'
                                                        }}>{moment(item.mensagens[id2].created_at).format('DD/MM/YYYY')}</p>
                                                    </div>}
                                                    {msg.atendente_id == this.props.user.id && <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                        <div
                                                            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                            <div
                                                                style={{ backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }}>
                                                                <p style={{ marginBottom: 0, color: 'black', marginRight: '0.5rem', padding: '5px', }}>
                                                                    {msg.message}</p>
                                                                <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3 }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')} <i className={msg.visto == undefined ? 'far fa-clock' : (msg.visto == false ? "fas fa-check" : "fas fa-check-double")}
                                                                    style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i></p>

                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {msg.atendente_id == null && <div
                                                        style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                        <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}
                                                        >
                                                            <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1rem' }}>
                                                                {msg.vendedor == null ? msg.vendedor_name : msg.vendedor.name}
                                                            </p>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end',minWidth:90 }}>
                                                                <p style={{ marginBottom: 0, color: 'black' }}>{msg.message}</p>
                                                                <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3,marginLeft:5 }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {msg.atendente_id != null && msg.atendente_id != this.props.user.id && <div
                                                        style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                        <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}
                                                        >
                                                            <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1rem' }}>
                                                                {msg.atendente.name}</p>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                                <p style={{ marginBottom: 0, color: 'black' }}>{msg.message}</p>
                                                                <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3,marginLeft:'0.5rem' }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>

                                <div hidden={this.state.conversa_select == ''}
                                    style={{ borderBottomRightRadius: '10px', height: '50px', backgroundColor: 'rgba(255,255,255,0.5)', borderTop: '1px solid rgba(255,255,255,0.5)', padding: '5px', display: 'flex' }}>
                                    <textarea value={this.state.mensagem} onChange={(e) => {
                                        this.setState({ mensagem: e.target.value })
                                    }} onKeyPress={(e) => {
                                        if (e.key == 'Enter') {
                                            e.preventDefault();
                                            let uid_msg = uuidv4();
                                            let conversa_id = this.state.conversa_select;
                                            let conversas = this.state.conversas;
                                            let vendedor_id = null;
                                            let vendedor_uuid = '';
                                            let msg = this.state.mensagem;

                                            if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
                                                let protocolo = '';
                                                for (let i = 0; i < conversas.length; i++) {
                                                    if (conversa_id == conversas[i].id) {
                                                        console.log('entrou');
                                                        console.log(conversas[i]);

                                                        vendedor_uuid = conversas[i].vendedor_uuid;
                                                        vendedor_id = conversas[i].vendedor_id;
                                                        protocolo = conversas[i].protocolo;

                                                        conversas[i].mensagens.push({
                                                            atendente: this.props.user,
                                                            atendente_id: this.props.user.id,
                                                            message: msg,
                                                            uuid: uid_msg,
                                                            conversa_id: conversa_id,
                        created_at:moment().format('YYYY-MM-DD HH:mm:ss'),

                                                        })
                                                        conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + msg.substring(0, 20) + (msg.length > 20 ? '...' : '');
                                                        break;
                                                    }
                                                }
                                                this.shared_worker.postMessage({ empresa_id: this.state.empresa_id, vendedor_uuid, id_msg: uid_msg, vendedor_id: vendedor_id, msg: this.state.mensagem, status: 'send_msg', atendente_name: this.props.user.name, atendente_id: this.props.user.id, conversa_id, protocolo })
                                                // socket.emit("send_msg_fiscal", {msg:msg,sala_id:sala_id,aluno_id:aluno_id,nome_chat:$('#nome_chat').text().split(' ')[0]+' '+$('#nome_chat').text().split(' ')[1],id_msg:uid_msg,faculdade_id:faculdade_id});
                                                this.setState({ mensagem: '', conversas }, () => {
                                                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                                });
                                            }
                                        }
                                    }} name="msg" placeholder="Mensagem..." className="form-control" style={{ resize: 'none', minHeight: 0, margin: 0 }} ></textarea>
                                    <div className="send_msg"
                                        onClick={() => {
                                            let uid_msg = uuidv4();
                                            let conversa_id = this.state.conversa_select;
                                            let conversas = this.state.conversas;
                                            let vendedor_id = null;
                                            let vendedor_uuid = '';
                                            let msg = this.state.mensagem;

                                            if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
                                                let protocolo = '';

                                                for (let i = 0; i < conversas.length; i++) {
                                                    if (conversa_id == conversas[i].id) {
                                                        console.log('entrou');
                                                        console.log(conversas[i]);

                                                        vendedor_uuid = conversas[i].vendedor_uuid;
                                                        vendedor_id = conversas[i].vendedor_id;
                                                        protocolo = conversas[i].protocolo;

                                                        conversas[i].mensagens.push({
                                                            atendente: this.props.user,
                                                            atendente_id: this.props.user.id,
                                                            message: msg,
                                                            uuid: uid_msg,
                                                            conversa_id: conversa_id,
                        created_at:moment().format('YYYY-MM-DD HH:mm:ss'),

                                                        })
                                                        conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + msg.substring(0, 20) + (msg.length > 20 ? '...' : '');
                                                        let conversa_element = { ...conversas[i] };
                                                        conversas = conversas.filter((item, id) => item.id != conversa_element.id);
                                                        conversas.unshift(conversa_element);
                                                        break;
                                                    }
                                                }
                                                this.shared_worker.postMessage({ protocolo, empresa_id: this.state.empresa_id, vendedor_uuid, id_msg: uid_msg, vendedor_id: vendedor_id, msg: this.state.mensagem, status: 'send_msg', atendente_name: this.props.user.name, atendente_id: this.props.user.id, conversa_id })
                                                // socket.emit("send_msg_fiscal", {msg:msg,sala_id:sala_id,aluno_id:aluno_id,nome_chat:$('#nome_chat').text().split(' ')[0]+' '+$('#nome_chat').text().split(' ')[1],id_msg:uid_msg,faculdade_id:faculdade_id});
                                                this.setState({ mensagem: '', conversas }, () => {
                                                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                                });
                                            }
                                        }}
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '30px', cursor: 'pointer', paddingRight: '10px' }}>
                                        <i className="far fa-paper-plane" style={{ fontSize: '1rem' }}></i>

                                    </div>
                                    <button onClick={() => {

                                        this.setState({ modal_open_finalizar: true, msgErroJustificativa: '' });

                                        // this.enviar_resposta();
                                    }} type="button" className="btn btn-success btn-lg waves-effect waves-light">
                                        <span>Finalizar</span>
                                        <i className="mdi mdi-send ml-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>}

                        {conversas.length == 0 && <div className="row d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <p style={{ textAlign: "center" }}>Nenhuma conversa iniciada</p>
                        </div>}
                        <div hidden={this.state.loading == false} id="finalizar_spinner"
                            style={{ position: 'absolute', top: '0px', left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <div style={{ display: 'block' }} className="spinner-border text-primary" role="status">
                            </div>
                        </div>
                    </div>
                </div >
                <Modal
                    show={this.state.modal_open == true}
                    onHide={() => this.setState({ modal_open: false })}
                >
                    <Modal.Header closeButton><Modal.Title>Novo Chamado<br />Empresa {this.state.empresa.nome}</Modal.Title></Modal.Header>

                    {/* <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                        this.setState({ modal_open: false });
                    }}>
                        <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                    </div> */}
                    {/* <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                        <h4 style={{ textAlign: 'center', fontSize: '1.5rem', color: 'black' }}>Novo Chamado - Empresa {this.state.empresa.nome}</h4>
                    </div> */}
                    <Modal.Body>

                        <div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-3">
                                    <p style={{ color: 'black', textarea: 'center', fontWeight: 400, fontSize: '1rem' }}>Envie uma mensagem para o vendedor selecionado
                                </p>

                                </div>

                            </div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Vendedor</label>
                                <div className="col-sm-12">
                                    <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_vendedor: true })} className="form-control" value={this.state.vendedor_name} placeholder="Nome do vendedor da empresa" type="text" onChange={(e) => { this.get_vendedor_search(e.target.value); this.setState({ vendedor_name: e.target.value }) }} />
                                    {this.state.show_autocomplete_vendedor == true && this.state.vendedor_name != '' && <div className="overflow-auto" style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto' }}>
                                        {this.state.loading_search_vendedor == false && this.state.vendedors.map((item, id) => (
                                            <div key={id} onClick={() => this.select_vendedor(item)} className="opcao_autocomplete">
                                                {item.name}
                                            </div>
                                        ))}
                                        {this.state.loading_search_vendedor == false && this.state.vendedors.length == 0 &&
                                            <div className="opcao_autocomplete">
                                                Nenhum vendedor encontrado com este termo
                                                        </div>
                                        }
                                        {this.state.loading_search_vendedor == true &&
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div className="spinner-border text-primary" role="status"  >
                                                    <span className="sr-only">Loading...</span>
                                                </div></div>}
                                    </div>}
                                </div>


                            </div>

                            {Object.values(this.state.vendedor_selecionado).length != 0 && <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Vendedor Selecionado:</label>

                                {Object.values(this.state.vendedor_selecionado).map((item, id) => (
                                    <div key={id} className="col-sm-12 col-12 col-md-12 col-lg-12" style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <span>{item.name}</span>
                                        <button onClick={() => {
                                            let users = { ...this.state.vendedor_selecionado };
                                            delete (users[item.id]);
                                            this.setState({ vendedor_selecionado: users });
                                        }}
                                            className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                className="ti-trash" style={{ color: 'red' }}></i></button>
                                    </div>
                                ))}

                            </div>}
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Assunto <b style={{ color: 'red' }}>*</b></label>

                                <div className="col-sm-12">
                                    <input value={this.state.assunto} onChange={(e) => this.setState({ assunto: e.target.value })} type="text" placeholder="Assunto" className="form-control" required />

                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Mensagem <b style={{ color: 'red' }}>*</b></label>

                                <div className="col-sm-12">
                                    <textarea value={this.state.mensagem_inicial} onChange={(e) => this.setState({ mensagem_inicial: e.target.value })} type="text" placeholder="Mensagem" className="form-control" required style={{ minHeight: 100 }} />

                                </div>
                            </div>

                            <p style={{ color: 'red' }}>{this.state.msgErroResposta}</p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>


                        {this.state.loading_save == false && <button onClick={() => {
                            if (Object.values(this.state.vendedor_selecionado).length == 0) {
                                this.setState({ msgErroResposta: 'O campo Vendedor é obrigatório' });
                                return;
                            }
                            if (this.state.assunto.trim().length == 0) {
                                this.setState({ msgErroResposta: 'O campo Assunto é obrigatório' });
                                return;
                            }
                            if (this.state.mensagem_inicial.trim().length == 0) {
                                this.setState({ msgErroResposta: 'O campo Mensagem é obrigatório' });
                                return;
                            }
                            this.setState({ loading_save: true, msgErroResposta: '' });
                            this.shared_worker.postMessage({assunto:this.state.assunto, empresa_id: this.state.empresa_id, vendedor_uuid: uuidv4(), id_msg: uuidv4(), vendedor_id: Object.values(this.state.vendedor_selecionado)[0].id, vendedor_name: Object.values(this.state.vendedor_selecionado)[0].name, vendedor_email: Object.values(this.state.vendedor_selecionado)[0].email, mensagem: this.state.mensagem_inicial, status: 'criar_chamado', atendente_name: this.props.user.name, atendente_id: this.props.user.id })

                            // this.enviar_resposta();
                        }} type="button" className="btn btn-success btn-lg waves-effect waves-light">
                            <span>Enviar</span>
                            <i className="mdi mdi-send ml-2"></i>
                        </button>}
                        {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                    </Modal.Footer>


                </Modal>



                <Modal
                    show={this.state.modal_open_finalizar == true}
                    onHide={() => this.setState({ modal_open_finalizar: false })}
                >
                    <Modal.Header closeButton><Modal.Title>Finalizar Chamado</Modal.Title></Modal.Header>

                    {/* <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                        this.setState({ modal_open: false });
                    }}>
                        <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                    </div> */}
                    {/* <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                        <h4 style={{ textAlign: 'center', fontSize: '1.5rem', color: 'black' }}>Novo Chamado - Empresa {this.state.empresa.nome}</h4>
                    </div> */}
                    <Modal.Body>

                        <div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-3">
                                    <p style={{ color: 'black', textarea: 'center', fontWeight: 400, fontSize: '1rem' }}>Escreva uma justificativa sobre como foi resolvido o problema
                                </p>

                                </div>

                            </div>

                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Justificativa <b style={{ color: 'red' }}>*</b></label>

                                <div className="col-sm-12">
                                    <textarea value={this.state.justificativa} onChange={(e) => this.setState({ justificativa: e.target.value })} type="text" placeholder="Justificativa" className="form-control" required style={{ minHeight: 100 }} />

                                </div>
                            </div>

                            <p style={{ color: 'red' }}>{this.state.msgErroJustificativa}</p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>


                        {this.state.loading_finalizar == false && <button onClick={() => {

                            if (this.state.justificativa.trim().length == 0) {
                                this.setState({ msgErroJustificativa: 'O campo Justificativa é obrigatório' });
                                return;
                            }

                            this.setState({ loading_finalizar: true, msgErroJustificativa: '' });
                            this.shared_worker.postMessage({ conversa_id: this.state.conversa_select, justificativa: this.state.justificativa, status: 'finalizar', empresa_id: this.state.empresa_id, vendedor_id: this.state.vendedor_select, protocolo: this.state.protocolo_select });

                            // this.enviar_resposta();
                        }} type="button" className="btn btn-success btn-lg waves-effect waves-light">
                            <span>Enviar</span>
                            <i className="mdi mdi-send ml-2"></i>
                        </button>}
                        {this.state.loading_finalizar == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                    </Modal.Footer>


                </Modal>

                <SweetAlert
                    success
                    title={"Sucesso"}
                    onConfirm={() => {
                        this.setState({ show_sucesso: false });
                    }}
                    show={this.state.show_sucesso}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"

                >
                    Chamado finalizado com sucesso

                    </SweetAlert>
            </div>

        )
    }

    select_vendedor(item) {
        let users = {};
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ vendedor_name: '', show_autocomplete_vendedor: false, vendedor_selecionado: users })
    }
}

export const mapStateToProps = (state) => ({
    token: state.AppReducer.token,
    user: state.AppReducer.user,
})

export default connect(mapStateToProps, { logout, mudarTitle })(ChatVendedor);