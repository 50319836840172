import React from 'react';
import Header from './header';
import { connect } from 'react-redux';
import { logout, mudarUser,mudarTitle,login } from '../actions/AppActions';
import { mudarModeloLoja } from '../actions/ClienteActions';

import { URL } from '../variables';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProcessoUser from './ProcessoUser';
import MenuItens from './Admin_Colab/MenuItens';
import Usuarios from './Admin_Colab/users/Usuarios';
import CriarUsuario from './Admin_Colab/users/CriarUsuario';
import EditarUsuario from './Admin_Colab/users/EditarUsuario';
import PageTitle from './PageTitle';

import Empresas from './Admin_Colab/empresas/Empresas';
import CriarEmpresa from './Admin_Colab/empresas/CriarEmpresa';
import EditarEmpresa from './Admin_Colab/empresas/EditarEmpresa';
import VerEmpresa from './Admin_Colab/empresas/VerEmpresa';
import CriarProduto from './Admin_Colab/empresas/Produtos/CriarProduto';
import EditarProduto from './Admin_Colab/empresas/Produtos/EditarProduto';
import Categorias from './Admin_Colab/Categorias/Categorias';
import CriarCategoria from './Admin_Colab/Categorias/CriarCategoria';
import EditarCategoria from './Admin_Colab/Categorias/EditarCategoria';
import AdicionarGerente from './Admin_Colab/empresas/Gerentes/AdicionarGerente';

import MinhasEmpresas from './Empresa_Gerente/empresas/Empresas';

import EditarMinhaEmpresa from './Empresa_Gerente/empresas/EditarEmpresa';
import VerMinhaEmpresa from './Empresa_Gerente/empresas/VerEmpresa';

import CriarMeuProduto from './Empresa_Gerente/empresas/Produtos/CriarProduto';

import EditarMeuProduto from './Empresa_Gerente/empresas/Produtos/EditarProduto';
import AdicionarMeuGerente from './Empresa_Gerente/empresas/Gerentes/AdicionarGerente';
import EditarGerente from './Empresa_Gerente/empresas/Gerentes/EditarGerente';
import MenuItensEmpresa from './Empresa_Gerente/MenuItens';
import ChatVendedor from './Empresa_Gerente/ChatVendedor/ChatVendedor';
import ChatsTabs from './Empresa_Gerente/ChatVendedor/ChatsTabs';
import VerVenda from './Admin_Colab/empresas/Vendas/VerVenda';
import VerSimulacao from './Admin_Colab/empresas/Simulacoes/VerSimulacao';

import VerVendaG from './Empresa_Gerente/empresas/Vendas/VerVenda';
import VerSimulacaoG from './Empresa_Gerente/empresas/Simulacoes/VerSimulacao';
import Chamados from './Empresa_Gerente/empresas/Chamados';
import ChatsTabsFechado from './Empresa_Gerente/ChamadosFechados/ChatsTabsFechado';
import Avisos from './Empresa_Gerente/Avisos/Avisos';
import CriarAviso from './Empresa_Gerente/Avisos/CriarAviso';

import AvisosC from './Admin_Colab/Avisos/Avisos';
import CriarAvisoC from './Admin_Colab/Avisos/CriarAviso';
import ChamadosAdmin from './Admin_Colab/ChamadosAdmin';
import ChamadosAbertosAdmin from './Admin_Colab/ChatVendedor/ChamadosAbertosAdmin';
import ChamadosFechadosAdmin from './Admin_Colab/ChamadosFechados/ChamadosFechadosAdmin';
import UsuariosInativos from './Admin_Colab/UsuariosInativos/UsuariosInativos';
import VerCliente from './Admin_Colab/UsuariosInativos/Clientes/VerCliente';
import VerVendedor from './Admin_Colab/UsuariosInativos/Vendedores/VerVendedor';

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    }
  />
);
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // redirect:'/not_processo',
      loading_home: true,
      num_produtos:0,
      total_mes:0,
      num_vendedores:0,
      num_empresas:0,
      total_meses:{},

      num_vendas:0,
      num_vendas_sim:0,
      empresas:[],
      produtos:[]
    }
    this.videoRef = React.createRef();

    // this.videoRef=null;
  }
  logout() {
    localStorage.removeItem('token');
    // this.setState({redirect:true,path:'/'});
    this.props.logout();
  }

  componentDidMount() {
    // this.setState({loading_home:true});
    console.log('lixo')
    this.get_user();

    // console.log('montou');
    // var socket = io.connect("http://localhost:3001", { "transports": ['websocket'] });
    // socket.on('welcome', function (data) {
    //   console.log(data);
    //   // me = data.id;
    //   // socket.emit("join", { name: 'Aluno', sala_id: -1, aluno_id: aluno_id, faculdade_id: faculdade_id });
    // });
    // socket.on("comunicado_geral" + faculdade_id, function (data) {
    //   let id_msg_comunicado = uuidv4();
    //   $('#msg_notifi_comunicado').append(template_msg_notifi_comunicado.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'comunicado' + id_msg_comunicado));
    //   setTimeout(() => {
    //     $('#comunicado' + id_msg_comunicado).remove();
    //   }, 15000);
    // });


  }

  get_user() {
    fetch(`${URL}api/get_user/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          this.setState({ loading_home: false });
        }
        else {
          this.props.mudarUser(resp.user);
          // localStorage.setItem('token',resp.token.original.access_token);
          // this.props.login({token:resp.token.original.access_token,user:resp.user});
          if(resp.user.role_name=='admin'){
            this.setState({ loading_home: false,
              num_produtos:resp.num_produtos,
              num_empresas:resp.num_empresas,
              num_vendedores:resp.num_vendedores,
              total_mes:resp.total_mes,
              total_meses:resp.total_meses,
              empresas:resp.empresas_top,


            });
          }
          else{
            this.setState({ loading_home: false,
              num_produtos:resp.num_produtos,
              num_vendas:resp.num_vendas,
              num_vendas_sim:resp.num_vendas_sim,
              total_mes:resp.total_mes,
              total_meses:resp.total_meses,
              produtos:resp.produtos_top,

            });
          }
          
          
          // this.props.mudarFaculdade(resp.faculdade);
          // // if (resp.processo != null) {
          // this.props.mudarProcesso(resp.processo);
          // // }
          // // if (resp.sala != null) {
          // this.props.mudarSala(resp.sala);
          // // }
          // this.props.mudarLoadingHome(false);

        }




      } catch (err) {
        console.log(err);
        this.setState({ loading_home: false });

      }

    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading_home: false });
      });
  }



  render() {
    return (
      <div>
        {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

        <div id="wrapper">
          <Header />
          {this.state.loading_home == false && <div className="">

            <div className="content-page" style={{ marginLeft: 0 }}>
              <div className="content">


                <div className="container-fluid">
                  <PageTitle />
                  {/* {this.props.processo != null &&
                   } */}
                  
                  {(this.props.user.role_name == 'admin' || this.props.user.role == 'Colaborador') && <Switch>
                    <Route exact path={`${this.props.match.url}`} render={(props2) => <MenuItens 
                      num_produtos={this.state.num_produtos} 
                      num_empresas={this.state.num_empresas} 
                      total_mes={this.state.total_mes} 
                      num_vendedores={this.state.num_vendedores} 
                      total_meses={this.state.total_meses} 
                      empresas={this.state.empresas} 
                      {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios`} render={(props2) => <Usuarios {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios/criar`} render={(props2) => <CriarUsuario {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios/editar/:id`} render={(props2) => <EditarUsuario {...props2} />} />
                    {/* <Route exact path={`${this.props.match.url}modelos`} render={(props2) => <Modelos {...props2} />} /> */}

                    <Route exact path={`${this.props.match.url}empresas`} render={(props2) => <Empresas {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/criar`} render={(props2) => <CriarEmpresa {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/editar/:id`} render={(props2) => <EditarEmpresa {...props2} />} />
                    <Route exact path={`${this.props.match.url}ver_empresa/:id`} render={(props2) => <VerEmpresa {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/produtos/criar/:id`} render={(props2) => <CriarProduto {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/produtos/editar/:id`} render={(props2) => <EditarProduto {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/gerentes/adicionar/:id`} render={(props2) => <AdicionarGerente {...props2} />} />
                    
                    <Route exact path={`${this.props.match.url}empresas/vendas/:id`} render={(props2) => <VerVenda {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/simulacoes/:id`} render={(props2) => <VerSimulacao {...props2} />} />

                    <Route exact path={`${this.props.match.url}categorias`} render={(props2) => <Categorias {...props2} />} />
                    <Route exact path={`${this.props.match.url}categorias/criar`} render={(props2) => <CriarCategoria {...props2} />} />
                    <Route exact path={`${this.props.match.url}categorias/editar/:id`} render={(props2) => <EditarCategoria {...props2} />} />

                    <Route exact path={`${this.props.match.url}avisos`} render={(props2) => <AvisosC {...props2} />} />
                    <Route exact path={`${this.props.match.url}novo_aviso`} render={(props2) => <CriarAvisoC {...props2} />} />


                    <Route exact path={`${this.props.match.url}chamados`} render={(props2) => <ChamadosAdmin {...props2} />} />
                    <Route exact path={`${this.props.match.url}chamados_abertos`} render={(props2) => <ChamadosAbertosAdmin {...props2} />} />
                    <Route exact path={`${this.props.match.url}chamados_fechados`} render={(props2) => <ChamadosFechadosAdmin {...props2} />} />


                    <Route exact path={`${this.props.match.url}usuarios_inativos`} render={(props2) => <UsuariosInativos {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios_inativos/cliente/:id`} render={(props2) => <VerCliente {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios_inativos/vendedor/:id`} render={(props2) => <VerVendedor {...props2} />} />

                    {/* <Route exact path={`${this.props.match.url}cursos`} render={(props2) => <Cursos {...props2} />} />
                     <Route exact path={`${this.props.match.url}cursos/criar`} render={(props2) => <CriarCurso {...props2} />} />
                     <Route exact path={`${this.props.match.url}cursos/editar/:id`} render={(props2) => <EditarCurso {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_curso/:id`} render={(props2) => <VerCurso {...props2} />} />

                     <Route exact path={`${this.props.match.url}turmas/:id`} render={(props2) => <Turmas {...props2} />} />
                     <Route exact path={`${this.props.match.url}turmas/criar/:id`} render={(props2) => <CriarTurma {...props2} />} />
                     <Route exact path={`${this.props.match.url}turmas/editar/:id`} render={(props2) => <EditarTurma {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_turma/:id`} render={(props2) => <VerTurma {...props2} />} />

                     <Route exact path={`${this.props.match.url}disciplinas`} render={(props2) => <Disciplinas {...props2} />} />
                     <Route exact path={`${this.props.match.url}disciplinas/criar/`} render={(props2) => <CriarDisciplina {...props2} />} />
                     <Route exact path={`${this.props.match.url}disciplinas/editar/:id`} render={(props2) => <EditarDisciplina {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_disciplina/:id`} render={(props2) => <VerTurma {...props2} />} />
                     

                     <Route exact path={`${this.props.match.url}ofertas_disciplinas/:id`} render={(props2) => <Ofertas {...props2} />} />
                     <Route exact path={`${this.props.match.url}ofertas_disciplinas/criar/:id`} render={(props2) => <CriarOferta {...props2} />} />
                     <Route exact path={`${this.props.match.url}ofertas_disciplinas/editar/:id`} render={(props2) => <EditarOferta {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_oferta/:id`} render={(props2) => <VerOferta {...props2} />} /> */}

                    {/* <Route exact path={`${this.props.match.url}`} render={(props2) => <Termos {...props2} />} /> */}
                    {/* <Route exact path={`${this.props.match.url}`} render={(props2) => { return(<ProcessoUser {...props2} />)}} /> */}


                    {/* {this.props.processo == null && <Route exact path={`${this.props.match.url}`} component={(props2) => <NotProcesso {...props2} />} />}  */}

                    <OtherRoute path="*" path_name='/' />

                  </Switch>}
                  {(this.props.user.role_name == 'empresa' || this.props.user.role_name == 'gerente') && <Switch>
                  <Route exact path={`${this.props.match.url}`} render={(props2) => <MenuItensEmpresa {...props2} 
                   num_produtos={this.state.num_produtos} 
                   num_vendas={this.state.num_vendas} 
                   total_mes={this.state.total_mes} 
                   num_vendas_sim={this.state.num_vendas_sim} 
                   total_meses={this.state.total_meses} 
                   produtos={this.state.produtos} 
                  />} />

                  <Route exact path={`${this.props.match.url}empresas/`} render={(props2) => <MinhasEmpresas {...props2} />} />
                  <Route exact path={`${this.props.match.url}empresas/editar/:id`} render={(props2) => <EditarMinhaEmpresa {...props2} />} />
                    <Route exact path={`${this.props.match.url}ver_empresa/:id`} render={(props2) => <VerMinhaEmpresa {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/produtos/criar/:id`} render={(props2) => <CriarMeuProduto {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/produtos/editar/:id`} render={(props2) => <EditarMeuProduto {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/gerentes/adicionar/:id`} render={(props2) => <AdicionarMeuGerente {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/gerentes/editar/:id/:empresa`} render={(props2) => <EditarGerente {...props2} />} />
                    
                    <Route exact path={`${this.props.match.url}empresas/vendas/:id`} render={(props2) => <VerVendaG {...props2} />} />
                    <Route exact path={`${this.props.match.url}empresas/simulacoes/:id`} render={(props2) => <VerSimulacaoG {...props2} />} />


                    <Route exact path={`${this.props.match.url}chamados`} render={(props2) => <Chamados {...props2} />} />
                    <Route exact path={`${this.props.match.url}chamados_abertos`} render={(props2) => <ChatsTabs {...props2} />} />
                    <Route exact path={`${this.props.match.url}chamados_fechados`} render={(props2) => <ChatsTabsFechado {...props2} />} />


                    <Route exact path={`${this.props.match.url}avisos`} render={(props2) => <Avisos {...props2} />} />
                    <Route exact path={`${this.props.match.url}novo_aviso`} render={(props2) => <CriarAviso {...props2} />} />

                    <OtherRoute path="*" path_name='/' />

                  </Switch>}

                  {/* { this.props.children } */}
                  {/* {this.props.processo == null &&
                    <NotProcesso />} */}
                </div>
              </div>
            </div>
          </div>}
          {this.state.loading_home == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="spinner-border text-primary" role="status"  >
              <span className="sr-only">Loading...</span>
            </div>
          </div>}

        </div>

      </div >

    );
  }


}

const mapsStateToProps = (state) => (
  {
    user: state.AppReducer.user,
    token: state.AppReducer.token,
    loja: state.ClienteReducer.loja,

  }
);

export default connect(mapsStateToProps, { logout, mudarUser,mudarModeloLoja,mudarTitle,login })(Home);
