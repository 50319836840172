import React from 'react';
import { URL } from '../variables';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/AppActions';
import LOGO from '../assets/images/hubi1.png';
import background from '../assets/images/posto.jpg';
import amex from '../assets/images/cartoes/amex.png';
import elo from '../assets/images/cartoes/elo.png';
import hypercard from '../assets/images/cartoes/hypercard.png';
import mastercard from '../assets/images/cartoes/mastercard.png';
import visa from '../assets/images/cartoes/visa.png';
import creditCardType from 'credit-card-type';
import { maskNumber, maskCpfCnpj, maskCartaoCredito, maskValidadeCartao, maskPrice } from './Auxiliar/Masks';
import moment from 'moment';

class LinkPagamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            msgErro: '',
            email: '',
            password: '',
            redirect: false,
            path: '/',
            loading_save: false,
            numero_cartao: '',
            cvv: '',
            validade: '',
            cpf_cnpj: '',
            nome: '',
            types: [],
            venda: {}
        }

    }

    salvar() {
        this.setState({loading_save:true});
        fetch(`${URL}api/auth/login_painel`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.setState({loading_save:false});
                    // this.setState({ redirect: true, path: '/home', loading: false });
                    // if (resp.faculdade != null) {
                    //     this.setState({ faculdade: resp.faculdade, loading: false, msgErro: '' });
                    // }
                    // else {
                    //     this.setState({ redirect: true, path: '/', loading: false });
                    // }
                    // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
            });
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        // this.setState({ loading: true, msgErro: '' });
        // this.get_faculdade(params.slug);
        this.get_venda(params.id);
    }

    changeEmail(event) {
        let text = event.target.value
        let final_num = '';
        // if(text.length>0 && isNaN(text[0])){
        final_num = text;
        this.setState({ email: final_num })



    }

    get_venda(id) {

        if (this.state.loading == false) {
            this.setState({ loading: true });
        }
        fetch(`${URL}api/pagamento/get_venda/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    if (resp.venda == null) {
                        this.setState({ venda: null, msgErro: 'Venda Inválida', loading: false });
                    }
                    else {
                        this.setState({ loading: false, venda: resp.venda });
                    }
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    changePassword(event) {
        this.setState({ password: event.target.value })
    }

    calcular_juros_produto(produto, dia_pagamento) {

        let valor = produto.preco * produto.quantidade;
        for (let i = 0; i < dia_pagamento; i++) {
            valor = valor * (1 + parseFloat(produto.percent_juros));
        }

        return valor;
    }

    render() {
        let logo = '';
        let brand = '';
        if (this.state.types.length == 1) {
            brand = this.state.types[0].niceType.toUpperCase();
            console.log(brand);
            if (brand.includes('VISA')) {
                logo = visa;
            }
            else if (brand.includes('MASTER')) {
                logo = mastercard;
            }
            else if (brand.includes('HYPER')) {
                logo = hypercard;
            }
            else if (brand.includes('AME')) {
                logo = amex;
            }
            else if (brand.includes('ELO')) {
                logo = elo;
            }

        }
        return (
            <div style={{ minHeight: '100vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {/* {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                </div></div>} */}
                {<div className="row" style={{ backgroundColor: 'white', margin: 0 }}>

                    <div className="col-md-12 col-sm-12 col-12 col-lg-12 col-xl-12 d-block" style={{ overflow: 'hidden', backgroundImage: `url(${background})`, backgroundSize: 'cover', padding: 0, margin: 0, minHeight: '100vh' }}>
                        <div className="row" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:30,marginBottom:30 }}>
                            <div className="col-md-10 col-12 col-sm-12 col-lg-8 col-xl-6" style={{ margin: 0, padding: 0 }}>
                                <div className="card" style={{ margin: 0 }}>
                                    {this.state.loading == true && <div style={{ minHeight: 300, margin: 10, paddingTop: 100 }}>
                                        <h5 style={{ textAlign: 'center', color: 'black' }}>Carregando informações sobre o pagamento</h5>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div>

                                    </div>}

                                    {this.state.loading == false && <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>


                                        <div className="text-center">
                                            <a className="logo"><img src={LOGO}
                                                height="50" alt="logo" /></a>
                                        </div>

                                        <div style={{ padding: '1rem', paddingBottom: 0 }}>
                                            <h4 className=" m-b-5  " style={{ color: "black" }}>Venda</h4>
                                            {/* <p className="text-muted text-center" >Faça login para continuar.</p> */}
                                            {this.state.venda != null && <div className="col-12" style={{ padding: 0, marginTop: 15 }}>


                                                <div className="table-rep-plugin">
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr>

                                                                    <th>Nome</th>
                                                                    <th>Quantidade</th>

                                                                    <th>Preço</th>

                                                                    <th>Juros</th>
                                                                    <th>Total</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.venda.produtos.map((item, id) => (

                                                                    <tr key={id}>

                                                                        <th>
                                                                            <img src={URL + item.imagem} style={{ height: 60, width: 60, objectFit: 'contain', marginRight: 10 }} />
                                                                            {item.nome}
                                                                        </th>
                                                                        <td>{item.quantidade} {item.unidade}</td>

                                                                        <td>{maskPrice(parseFloat(item.preco).toFixed(2))}</td>
                                                                        {/* <td>{maskPrice(parseFloat(item.preco * item.quantidade).toFixed(2))}</td> */}
                                                                        <td>{this.state.venda.dia_pagamento == 0 ? maskPrice('0.00') : maskPrice(parseFloat(this.calcular_juros_produto(item, this.state.venda.dia_pagamento) - item.preco * item.quantidade).toFixed(2))}</td>

                                                                        <td>{maskPrice(parseFloat(this.calcular_juros_produto(item, this.state.venda.dia_pagamento)).toFixed(2))}</td>




                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {this.state.venda.produtos.length == 0 && <div className="row w-100">
                                                    <div className="col-12">
                                                        <p style={{ textAlign: 'center' }}>Nenhum produto nesta venda</p>

                                                    </div>
                                                </div>}

                                                <div className="col-12  pb-2" style={{paddingLeft:0,paddingRight:0}}>
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0,textAlign:'right' }}><b style={{ fontWeight: 'bold' }}>Subtotal:</b> {maskPrice(parseFloat(this.state.venda.valor - this.state.venda.frete).toFixed(2).toString())}</p>

                                                </div>
                                                <div className="col-12  pb-2" style={{paddingLeft:0,paddingRight:0}}>
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0,textAlign:'right' }}><b style={{ fontWeight: 'bold' }}>Frete:</b> {maskPrice(parseFloat(this.state.venda.frete).toFixed(2).toString())}</p>

                                                </div>

                                                <div className="col-12  pb-2" style={{paddingLeft:0,paddingRight:0}}>
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0,textAlign:'right' }}><b style={{ fontWeight: 'bold' }}>Total:</b> {maskPrice(parseFloat(this.state.venda.valor).toFixed(2))}</p>

                                                </div>
                                            </div>}

                                            <div >
                                                <hr />
                                                <div hidden={this.state.venda == null}>
                                                    <h4 className=" m-b-5  " style={{ color: "black", marginBottom: 15 }}>Pagamento</h4>
                                                </div>

                                                <div hidden={this.state.pagamento_hidden}>
                                                    {this.state.msgErro != '' && <div>
                                                        <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">


                                                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                                        </div>
                                                        <br />

                                                    </div>}
                                                    {this.state.venda != null && <div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Número do Cartão de Crédito <b style={{ color: 'red' }}>*</b></label>


                                                            <br />
                                                            <div className="col-12" style={{ position: 'relative' }}>

                                                                <input value={this.state.numero_cartao} onChange={(e) => {
                                                                    let text = e.target.value;
                                                                    let types = creditCardType(text);
                                                                    console.log(types)
                                                                    let credit_card = maskCartaoCredito(text, types);
                                                                    this.setState({ numero_cartao: credit_card, types: types });
                                                                }} style={{
                                                                    paddingRight: 40,
                                                                }} type="text" placeholder="Número do Cartão" className="form-control" required />
                                                                {logo != '' && <div style={{ position: 'absolute', top: 0, right: 10 }}>
                                                                    <img src={logo} style={{ width: 30, height: 40, objectFit: "contain" }} />
                                                                </div>}
                                                                <div className="d-flex">
                                                                    <img src={visa} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('VISA') ? '' : 'grayscale(1)' }} />
                                                                    <img src={mastercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('MASTER') ? '' : 'grayscale(1)' }} />
                                                                    <img src={amex} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('AME') ? '' : 'grayscale(1)' }} />
                                                                    <img src={elo} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('ELO') ? '' : 'grayscale(1)' }} />
                                                                    <img src={hypercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('HYPER') ? '' : 'grayscale(1)' }} />

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-1">
                                                            <div className="col-12 col-md-6">
                                                                <label className="col-form-label" style={{ color: 'black' }}>Validade (MM/YY) <b style={{ color: 'red' }}>*</b></label>
                                                                <input style={{ marginBottom: '0.5rem' }} value={this.state.validade} onChange={(e) => this.setState({ validade: maskValidadeCartao(e.target.value) })} type="text" placeholder="Validade MM/YY" className="form-control" required />
                                                                {this.state.validade != '' && (!moment(this.state.validade, "MM/YY", true).isValid() || moment(this.state.validade, "MM/YY", true).diff(moment()) <= 0) && <small className="form-text" style={{ color: 'red' }}>Validade Inválida</small>}

                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className=" col-form-label" style={{ color: 'black' }}>CVV <b style={{ color: 'red' }}>*</b></label>
                                                                <input value={this.state.cvv} onChange={(e) => this.setState({ cvv: maskNumber(e.target.value) })} type="text" placeholder="CVV" className="form-control" required />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome do Titular <b style={{ color: 'red' }}>*</b></label>
                                                            <div className="col-sm-12">
                                                                <input value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} type="text" placeholder="Nome do Titular do Cartão" required className="form-control" />
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CPF/CNPJ <b style={{ color: 'red' }}>*</b></label>
                                                            <div className="col-sm-12">
                                                                <input value={this.state.cpf_cnpj} onChange={(e) => this.setState({ cpf_cnpj: maskCpfCnpj(e.target.value) })} type="text" placeholder="CPF/CNPJ do Titular do Cartão" className="form-control" required />
                                                            </div>
                                                        </div>




                                                        {this.state.loading_save == false &&
                                                            <div className="col-12 col-md-4 offset-md-8" style={{ padding: 0 }}>
                                                                <button type="button" onClick={() => this.salvar()} className="btn btn-block btn-success btn-lg waves-effect waves-light"
                                                                    style={{ backgorundColor: '#f2f2f2' }}>Salvar </button>
                                                            </div>
                                                        }
                                                        {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div></div>}
                                                    </div>}

                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="text-center mt-4">
                                            <p>© 2021 Hubi</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { login })(LinkPagamento);
