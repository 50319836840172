
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs } from 'react-bootstrap';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import { maskPrice } from '../../Auxiliar/Masks';
import Vendedores from './Vendedores/Vendedores';
import Clientes from './Clientes/Clientes';


class UsuariosInativos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: false,
            msgErro: '',
            tab: 0,
            telefones: [],
        };
    }






    componentDidMount() {
        this.props.mudarTitle('Usuários Inativos');

    }

  





    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="float-right">
                                <Link to={"/"}><span
                                    className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                            </div>

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 d-flex">
                                       

                                        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                            <div>

                                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Usuários Inativos</h4>
                                                <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são exibidos todos os usuários que não acessaram/compraram nada nos últimos 3 meses.</p>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                              
                                <Clientes  />
                                <Vendedores  />


                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(UsuariosInativos);


