
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs } from 'react-bootstrap';

// users
import moment from 'moment';
import { URL } from '../../../../variables';
import { maskPrice } from '../../../Auxiliar/Masks';


class VerSimulacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            pedido: { empresa: { id: this.props.match.params.id } },
            msgErro: '',
            telefones: [],
            categorias: []
        };
    }






    componentDidMount() {
        this.get_pedido(this.props.match.params.id);
    }

    get_pedido(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_simulacao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.props.mudarTitle(resp.pedido.empresa.nome + ' - Simulação #' + resp.pedido.id);

                    this.setState({ loading_screen: false, pedido: resp.pedido });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    render() {
        let pedido = this.state.pedido;

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="float-right">
                                <Link to={"/ver_empresa/" + pedido.empresa.id}><span
                                    className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                            </div>

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 d-flex">
                                        {/* <div style={{ height: 130, border: '1px solid lightgrey', borderRadius: 1000, marginRight: 15, width: 130, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                            <img src={URL + empresa.imagem} alt="img" class="gallery-thumb-img" style={{ height: 100, borderRadius: 0, width: 100, objectFit: 'contain' }} />
                                        </div> */}

                                        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                            <div>

                                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Pedido #{pedido.id}</h4>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 pt-4 pb-2">
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Empresa:</b> {pedido.empresa.nome}</p>}

                                    </div>
                                    <div className="col-12  pb-2">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone Empresa:</b> {pedido.empresa.telefone}</p>

                                    </div>
                                    <div className="col-12  pb-2">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Forma de Pagamento:</b> {pedido.forma_pagamento}</p>

                                    </div>
                                    <div className="col-12  pb-2">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Pagamento:</b> {pedido.dia_pagamento == 0 ? 'Á vista' : 'Em ' + pedido.dia_pagamento + ' dias'}</p>

                                    </div>
                                    <div className="col-12  pb-2">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Subtotal:</b> {maskPrice(parseFloat(pedido.valor - pedido.frete).toFixed(2).toString())}</p>

                                    </div>
                                    {pedido.entrega == true && <div className="col-12  pb-2">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Frete:</b> {maskPrice(parseFloat(pedido.frete).toFixed(2))}</p>

                                    </div>}
                                    {<div className="col-12  pb-2">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Serviço:</b> {pedido.entrega == true ? 'Entrega' : 'Retirada'}</p>

                                    </div>}
                                    <div className="col-12  pb-2" >
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Total:</b> {maskPrice(parseFloat(pedido.valor).toFixed(2))}</p>

                                    </div>
                                    <div className="col-12  pb-4" style={{ borderBottom: '1px solid lightgrey' }}>
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Status: </b>
                                            {<span style={{ color: 'green' }}>Pedido foi finalizado (Simulado)</span>}

                                        </p>

                                    </div>


                                    <div className="col-12 pt-4 pb-2">
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Cliente:</b> {pedido.cliente.name}</p>}

                                    </div>
                                    <div className="col-12 pb-2">
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone(s) Cliente:</b> {pedido.cliente.telefones.map((item, id) => <span key={id}><br />{item}</span>)}</p>}

                                    </div>
                                    <div className="col-12 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>CPF/CNPJ Cliente:</b> {pedido.cliente.cnpj}</p>}

                                    </div>
                                    <div className="col-12 pt-4 pb-2">
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Vendedor:</b> {pedido.vendedor.name}</p>}

                                    </div>
                                    <div className="col-12 pb-2">
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone(s) Vendedor:</b> {pedido.vendedor.telefones.map((item, id) => <span key={id}><br />{item}</span>)}</p>}

                                    </div>
                                    <div className="col-12 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>CPF/CNPJ Vendedor:</b> {pedido.vendedor.cnpj}</p>}

                                    </div>
                                    <div className="col-12 pt-4 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>

                                        <p style={{ fontSize: '1rem', marginBottom: '.25rem', color: 'black' }}><b style={{ fontWeight: 'bold' }}>{pedido.entrega == true ? 'Endereço de Entrega' : 'Endereço de Retirada'}:</b><br /><a href={'https://www.google.com.br/maps/place/' + pedido.rua.replace(/ /g, '+') + ',+' + pedido.numero + '+-+' + pedido.bairro.replace(/ /g, '+') + ',+' + pedido.cidade.replace(/ /g, '+') + '+-+' + pedido.estado.replace(/ /g, '+')}
                                            target="_blank" >{pedido.rua}, {pedido.numero} - {pedido.bairro}, {pedido.cidade} - {pedido.estado}
                                        </a></p>
                                    </div>
                                    <div className="col-12 pt-4 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>

                                        <p style={{ fontSize: '1rem', marginBottom: '.25rem', color: 'black' }}><b style={{ fontWeight: 'bold' }}>{'Endereço de Cobrança'}:</b><br /><a href={'https://www.google.com.br/maps/place/' + pedido.endereco_cobranca.rua.replace(/ /g, '+') + ',+' + pedido.endereco_cobranca.numero + '+-+' + pedido.endereco_cobranca.bairro.replace(/ /g, '+') + ',+' + pedido.endereco_cobranca.cidade.replace(/ /g, '+') + '+-+' + pedido.endereco_cobranca.estado.replace(/ /g, '+')}
                                            target="_blank" >{pedido.endereco_cobranca.rua}, {pedido.endereco_cobranca.numero} - {pedido.endereco_cobranca.bairro}, {pedido.endereco_cobranca.cidade} - {pedido.endereco_cobranca.estado}
                                        </a></p>
                                    </div>
                                    <div className="col-12 mt-4 mb-4">
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Produtos:</b></p>
                                    </div>
                                    <div className="col-12">


                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Nome</th>
                                                            <th>Quantidade</th>

                                                            <th>Preço</th>

                                                            <th>Juros</th>
                                                            <th>Total</th>

                                                            <th >Estoque</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pedido.produtos.map((item, id) => (

                                                            <tr key={id}>

                                                                <th>
                                                                    <img src={URL + item.imagem} style={{ height: 60, width: 60, objectFit: 'contain', marginRight: 10 }} />
                                                                    {item.nome}
                                                                </th>
                                                                <td>{item.quantidade} {item.unidade}</td>

                                                                <td>{maskPrice(parseFloat(item.preco).toFixed(2))}</td>
                                                                {/* <td>{maskPrice(parseFloat(item.preco * item.quantidade).toFixed(2))}</td> */}
                                                                <td>{pedido.dia_pagamento == 0 ? maskPrice('0.00') : maskPrice(parseFloat(this.calcular_juros_produto(item, pedido.dia_pagamento) - item.preco * item.quantidade).toFixed(2))}</td>

                                                                <td>{maskPrice(parseFloat(this.calcular_juros_produto(item, pedido.dia_pagamento)).toFixed(2))}</td>

                                                                <td>{item.estoque} {item.unidade}</td>



                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {pedido.produtos.length == 0 && <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhum produto cadastrado</p>

                                            </div>
                                        </div>}
                                    </div>

                                </div>


                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    calcular_juros_produto(produto, dia_pagamento) {

        let valor = produto.preco * produto.quantidade;
        for (let i = 0; i < dia_pagamento; i++) {
            valor = valor * (1 + parseFloat(produto.percent_juros));
        }


        return valor;
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(VerSimulacao);


