import React from 'react';
import { URL } from '../variables';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/AppActions';
import logo from '../assets/images/hubi1.png';
import background from '../assets/images/posto.jpg';


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            msgErro: '',
            email: '',
            password: '',
            redirect: false,
            path: ''
        }

    }

    login(event) {
        event.preventDefault();

        fetch(`${URL}api/auth/login_painel`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro, refresh: false, loadingMore: false });
                }
                else {
                    localStorage.setItem('token', resp.access_token);
                    this.props.login({ token: resp.access_token, user: {} });
                    // this.setState({ redirect: true, path: '/home', loading: false });
                    // if (resp.faculdade != null) {
                    //     this.setState({ faculdade: resp.faculdade, loading: false, msgErro: '' });
                    // }
                    // else {
                    //     this.setState({ redirect: true, path: '/', loading: false });
                    // }
                    // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
            });
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        // this.setState({ loading: true, msgErro: '' });
        // this.get_faculdade(params.slug);
    }

    changeEmail(event) {
        let text = event.target.value
        let final_num = '';
        // if(text.length>0 && isNaN(text[0])){
        final_num = text;
        this.setState({ email: final_num })
        // }
        // else if(text.length>0){
        //     text=text.replace('.', '').replace('-', '').replace('.', '');
        //     if (text.length <= 11) {


        //         for (let i = 0; i < text.length; i++) {
        //             // if (Number.isInteger(text[i])) {
        //             if (i == 3) {
        //                 final_num += '.' + text[i];
        //             }
        //             else if (i == 6) {
        //                 final_num += '.' + text[i];
        //             }
        //             else if (i == 9) {
        //                 final_num += '-' + text[i];
        //             }
        //             else {
        //                 final_num += text[i] + '';
        //             }
        //             // }
        //         }
        //         // this.setState({ cpf: final_num });
        //         this.setState({ credencial: final_num })
        //     }

        // }
        // else{
        //     this.setState({ credencial: final_num })
        // }


    }

    changePassword(event) {
        this.setState({ password: event.target.value })
    }

    render() {
        let img_ = require('../assets/images/click.jpg');
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                    <span className="sr-only">Loading...</span>
                </div></div>}
                {!this.state.loading && <div className="row" style={{ backgroundColor: 'white' }}>
                    <div className="col-md-6 col-12 col-sm-12 col-lg-4 col-xl-4" style={{ margin: 0, padding: 0 }}>
                        <div className="card" style={{ height: '100vh', margin: 0 }}>
                            <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>


                                <div className="text-center">
                                    <a className="logo"><img src={logo}
                                        height="100" alt="logo" /></a>
                                </div>

                                <div style={{ padding: '1rem', paddingBottom: 0 }}>
                                    <h4 className="font-18 m-b-5 text-center">Bem vindo !</h4>
                                    <p className="text-muted text-center">Faça login para continuar.</p>

                                    <form className="form-horizontal m-t-30" onSubmit={this.login.bind(this)}>

                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label >E-mail</label>
                                                <input id="email" type="username"
                                                    className="form-control  " name="email"
                                                    value={this.state.email} onChange={this.changeEmail.bind(this)} required autoComplete="email" autoFocus />

                                            </div>

                                        </div>

                                        <div className="form-group row">
                                            <div className="col-12">
                                                <label >Senha</label>
                                                <input id="password" type="password"
                                                    value={this.state.password}
                                                    onChange={this.changePassword.bind(this)}
                                                    className="form-control" name="password"
                                                    required autoComplete="current-password" />



                                            </div>

                                        </div>

                                        <div className="form-group row ">
                                            <div className="col-sm-12">
                                                <span className="invalid-feedback" style={{ display: 'flex', justifyContent: 'center' }} role="alert">
                                                    <strong style={{ textAlign: 'center', fontSize: '0.8rem' }}>{this.state.msgErro}</strong>
                                                </span>
                                            </div>
                                            <div className="col-sm-12 text-right">
                                                <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', }} type="submit">Entrar</button>

                                            </div>
                                        </div>

                                        <div className="form-group m-t-10 mb-0 row">
                                            <div className="col-12 m-t-20" style={{ textAlign: 'center' }}>

                                                <Link to='/recuperar_senha' className="color_link">
                                                    <i className="mdi mdi-lock mr-1"></i>Esqueceu sua senha?
                                                </Link>
                                                {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-center mt-3">
                                    <p>© 2021 Hubi</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-6 col-12 col-lg-8 col-xl-8 d-none d-md-block" style={{ overflow: 'hidden', backgroundImage: `url(${background})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                    </div>
                </div>}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { login })(Login);
