
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { maskCep, maskCpfCnpj, maskTelefone } from '../../Auxiliar/Masks';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class CriarUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/usuarios',
            loading_screen: true,
            roles: {},
            name: '',
            email: '',
            password: '',
            confirm_password: '',
            role_id: '',
            loading_save: false,
            msgErro: '',

            telefones: [''],
            cep: '',
            rua: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            complemento: '',
            loading_cep: false,
            cnpj: ''

        };
    }


    buscar_endereco(cep) {
        this.setState({ loading_cep: true });
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {

            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    rua: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: '',
                    loading_cep: false

                }, () => {
                    document.getElementById('numero').focus();
                    this.get_lat_lng();
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?', loading_cep: false });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`, loading_cep: false });
            });


    }

    get_lat_lng() {
        let address = `${this.state.rua},+${this.state.numero},+${this.state.bairro},+${this.state.cidade},+${this.state.estado},+Brasil`
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE&components=country=Brazil|region=BR`, {
            method: 'GET',
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.results.length > 0) {
                    this.setState({ latitude: resp.results[0].geometry.location.lat, longitude: resp.results[0].geometry.location.lng })
                }



            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/salvar_usuario`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.confirm_password,
                role_id: this.state.role_id,
                cnpj: this.state.cnpj,
                telefones: this.state.telefones,
                cep: this.state.cep,
                rua: this.state.rua,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                complemento: this.state.complemento,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro }, () => {
                        window.scrollTo(0, 0);

                    });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Usuários');

        this.get_roles();
    }

    get_roles() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_roles`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let roles = {};
                    for (let i = 0; i < resp.roles.length; i++) {
                        roles[resp.roles[i].id] = resp.roles[i];
                    }
                    console.log(roles)
                    this.setState({ loading_screen: false, roles: roles, role_id: resp.roles[0].id });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    change_roles(event) {
        this.setState({ role_id: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar Usuários</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre os usuários do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do usuário" type="text" onChange={(e) => this.setState({ name: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>E-mail <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="E-mail" type="text" onChange={(e) => this.setState({ email: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Senha <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Senha" type="password" onChange={(e) => this.setState({ password: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Confirmar Senha <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Confirmar Senha" type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Tipo de usuário <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.role_id} className="custom-select" id="cameras" onChange={this.change_roles.bind(this)}>
                                                    {Object.values(this.state.roles).map((item, id) => (
                                                        <option key={id} value={item.id}>{item.description}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div hidden={this.state.roles[this.state.role_id] != undefined && (this.state.roles[this.state.role_id].name != 'vendedor' && this.state.roles[this.state.role_id].name != 'cliente')}>
                                        <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CPF ou CNPJ <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <input className="form-control" value={this.state.cnpj} placeholder="CPF ou CNPJ do usuário" type="text" onChange={(e) => this.setState({ cnpj: maskCpfCnpj(e.target.value) })} />

                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Telefone(s) <b style={{ color: 'red' }}>*</b></label>

                                                {this.state.telefones.map((item, id) => (
                                                    <div className="col-sm-12 mb-3" key={id}>
                                                        <input className="form-control" value={item} placeholder="Telefone do usuário" type="text" onChange={(e) => {
                                                            let telefones = [...this.state.telefones]
                                                            telefones[id] = maskTelefone(e.target.value);
                                                            this.setState({ telefones });
                                                        }} />

                                                    </div>
                                                ))}

                                                <div className="col-12  d-flex justify-content-end justify-content-sm-end "
                                                    style={{ marginBottom: '0.5rem' }}>
                                                    <button type="button" onClick={() => {
                                                        let telefones = [...this.state.telefones]
                                                        telefones.push('');
                                                        this.setState({ telefones });
                                                    }} className="btn btn-primary  waves-effect waves-light mr-2"
                                                        style={{ backgorundColor: '#f2f2f2' }}>Adicionar +1 telefone</button>
                                                    <button hidden={this.state.telefones.length == 1} type="button" onClick={() => {
                                                        let telefones = [...this.state.telefones]
                                                        if (telefones.length > 1) {
                                                            telefones.pop();
                                                        }
                                                        console.log(telefones);
                                                        this.setState({ telefones });
                                                    }} className="btn btn-danger  waves-effect waves-light"
                                                        style={this.state.telefones.length == 1 ? { backgroundColor: 'grey', borderColor: 'grey' } : {}}>Remover -1</button>
                                                </div>
                                            </div>
                                            <hr />
                                            <p style={{ fontWeight: '600', color: 'black', fontSize: 16 }}>Endereço:</p>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CEP <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12" style={{ position: 'relative' }}>
                                                    <input disabled={this.state.loading_cep} className="form-control" value={this.state.cep} placeholder="CEP" type="text" onChange={(e) => {
                                                        this.setState({ cep: maskCep(e.target.value) });
                                                        let text = e.target.value.replace('-', '');
                                                        if (text.length == 8) {
                                                            this.buscar_endereco(text);
                                                        }
                                                    }} />
                                                    {this.state.loading_cep == true && <div style={{ position: 'absolute', top: 0, right: 10 }}>

                                                        <div style={{ height: 15, width: 15, marginLeft: 10 }} className="spinner-border text-primary" role="status"  >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>}

                                                </div>


                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Rua <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input disabled={this.state.loading_cep} className="form-control" value={this.state.rua} placeholder="Rua" type="text" onChange={(e) => this.setState({ rua: e.target.value }, () => this.get_lat_lng())} />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">

                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Número <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input disabled={this.state.loading_cep} className="form-control" value={this.state.numero} placeholder="Número" type="text" id="numero" onChange={(e) => this.setState({ numero: e.target.value }, () => this.get_lat_lng())} />

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Complemento</label>
                                                <div className="col-sm-12">
                                                    <input className="form-control" value={this.state.complemento} placeholder="Complemento" type="text" onChange={(e) => this.setState({ complemento: e.target.value })} />

                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Bairro <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <input disabled={this.state.loading_cep} className="form-control" value={this.state.bairro} placeholder="Bairro" type="text" onChange={(e) => this.setState({ bairro: e.target.value }, () => this.get_lat_lng())} />

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Cidade <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input disabled={this.state.loading_cep} className="form-control" value={this.state.cidade} placeholder="Cidade" type="text" onChange={(e) => this.setState({ cidade: e.target.value }, () => this.get_lat_lng())} />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6">

                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Estado (Sigla) <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input disabled={this.state.loading_cep} className="form-control" value={this.state.estado} placeholder="Estado (Sigla)" type="text" onChange={(e) => this.setState({ estado: e.target.value }, () => this.get_lat_lng())} />

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div style={{ height: 300, overflow: 'hidden' }}>

                                                        <Map
                                                            google={window.google}
                                                            style={{ height: 300, position: "relative" }}
                                                            center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                                            className="map2"
                                                            zoom={14}>
                                                            {this.state.latitude != '' && <Marker
                                                                title={'The marker`s title will appear as a tooltip.'}
                                                                name={'SOMA'}
                                                                position={{ lat: this.state.latitude, lng: this.state.longitude }} />}

                                                        </Map>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/usuarios"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar usuário</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(GoogleApiWrapper({ apiKey: 'AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE' })(CriarUsuario));


