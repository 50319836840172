
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../../variables';
import ReactPaginate from 'react-paginate';
import { maskPrice,maskNumber,maskDouble, maskPercent } from '../../../Auxiliar/Masks';

class CriarProduto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/ver_empresa/',
            loading_screen: true,
            nome: '',
            loading_save: false,
            msgErro: '',
            preco: '',
            preco_minimo:'',
            descricao: '',
            estoque: '',
            imagem: '',
            comissao: '',
            percent_juros: '',

            unidade:'Un',
            empresa: {},
            fotos: [],
            fotos_path: [],

        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('empresa_id', this.state.empresa.id);
        form.append('nome', this.state.nome);
        form.append('descricao', this.state.descricao);
        form.append('unidade', this.state.unidade);

        form.append('empresa_id', this.state.empresa.id);

        form.append('preco', this.state.preco.replace(/\./g, '').replace(',', '.').replace('R', '').replace('$', '').replace(' ', ''));
        form.append('preco_minimo', this.state.preco_minimo.replace(/\./g, '').replace(',', '.').replace('R', '').replace('$', '').replace(' ', ''));

        form.append('estoque', this.state.estoque.replace(/\,/,'.'));
        form.append('comissao', this.state.comissao);
        if(this.state.percent_juros.length>0){
            form.append('percent_juros', this.state.percent_juros/100);
        }

        for (let i = 0; i < this.state.fotos.length; i++) {

            form.append('fotos[]', this.state.fotos[i]);
        }

        fetch(`${URL}api/salvar_produto`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro }, () => {
                        window.scrollTo(0, 0);

                    });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_empresa(this.props.match.params.id);
    }

    get_empresa(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_empresa/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_screen: false, empresa: resp.empresa, });
                    // if (resp.processo != null) {
                    this.props.mudarTitle('Produtos - ' + resp.empresa.nome);


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path + this.props.match.params.id} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar Produtos</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre os produtos da empresa {this.state.empresa.nome}</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}

                                <div className="row">
                                    <div className="col-12">

                                        <label style={{ color: 'black' }}>Foto(s) <b style={{ color: 'red' }}>*</b></label>

                                        <input className="form-control" accept="image/*" multiple placeholder="Modelo" type="file" onChange={(e) => {
                                            let fotos = this.state.fotos;
                                            fotos.push(...e.target.files);
                                            this.setState({ fotos });
                                            let fotos_path = this.state.fotos_path;
                                            for (let i = 0; i < e.target.files.length; i++) {
                                                let reader = new FileReader()
                                                reader.readAsDataURL(e.target.files[i])
                                                reader.onload = () => {
                                                    fotos_path.push(reader.result);
                                                    this.setState({ fotos_path });
                                                };
                                                reader.onerror = function (error) {
                                                    console.log('Error: ', error);
                                                }
                                            }
                                            e.target.value = '';
                                        }} />
                                        <div className="row mt-2">
                                            {this.state.fotos_path.map((item, id) => (
                                                <div key={id} style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-6 col-md-3">
                                                    <div>
                                                        <img src={item} style={{ height: 100, width: 100, objectFit: "contain", border: '1px solid lightgrey' }} />
                                                        <p><a onClick={() => {
                                                            let fotos = this.state.fotos.filter((item2, id2) => {
                                                                if (id2 == id) {
                                                                    return false;
                                                                }
                                                                return true;
                                                            });
                                                            let fotos_path = this.state.fotos_path.filter((item2, id2) => {
                                                                if (id2 == id) {
                                                                    return false;
                                                                }
                                                                return true;
                                                            });
                                                            this.setState({ fotos, fotos_path });
                                                        }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span style={{ marginRight: 5 }}>Remover</span><i className="fas fa-times"></i></a></p>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </div>

                                    <hr />
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.nome} placeholder="Nome do produto" type="text" onChange={(e) => this.setState({ nome: e.target.value })} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Descrição <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <textarea className="form-control" value={this.state.descricao} placeholder="Descrição do produto" type="text" style={{ minHeight: 120 }} onChange={(e) => this.setState({ descricao: e.target.value })} ></textarea>

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Tipo de unidade <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.unidade} className="custom-select" onChange={(e) => { this.setState({ unidade: e.target.value,estoque:'' }) }}>

                                                <option value={'Un'}>Unidade (Un)</option>
                                                    <option value={'Pct'}>Pacote (Pct)</option>


                                                    <option value={'Kg'}>Kilograma (Kg)</option>
                                                    <option value={'L'}>Litro (L)</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Preço Máximo<b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input value={this.state.preco} className="form-control" placeholder="Preço Máximo" type="text" onChange={(e) => this.setState({ preco: maskPrice(e.target.value) })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Preço Mínimo<b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input value={this.state.preco_minimo} className="form-control" placeholder="Preço Mínimo" type="text" onChange={(e) => this.setState({ preco_minimo: maskPrice(e.target.value) })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Estoque <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input value={this.state.estoque} className="form-control" placeholder="Estoque" type="text" onChange={(e) => this.setState({ estoque: (this.state.unidade=='Un' || this.state.unidade=='Pct')? maskNumber(e.target.value):maskDouble(e.target.value) })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Juros Composto (Em porcentagem. Ex: 10%) <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input value={this.state.percent_juros} className="form-control" placeholder="Juros Composto (Em porcentagem. Ex: 10%) " type="text" onChange={(e) => this.setState({ percent_juros: maskPercent(e.target.value) })} />
                                                <small>Juros diário no valor final produto caso o cliente não pague á vista </small>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Valor de Comissão (Em porcentagem. Ex: 10%) <b style={{ color: 'red' }}></b></label>

                                            <div className="col-sm-12">
                                                <input value={this.state.comissao} className="form-control" placeholder="Comissão" type="text" onChange={(e) => this.setState({ comissao: maskPercent(e.target.value) })} />
                                                <small>Se preenchido, este será o valor de comissão utilizado para este produto, ao invés do valor geral da empresa</small>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to={"/ver_empresa/" + this.state.empresa.id}> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar produto</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(CriarProduto);


