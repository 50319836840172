import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL,THIS_URL } from '../../../variables';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout, mudarUser, mudarTitle,login } from '../../../actions/AppActions';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

class ChatCliente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mensagens: [],

            loading: true,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            vendedor_to_delete: '',
            email: '',
            name: '',
            foto: null,
            email_set: false,
            mensagem: '',
            close_chat: false,
            vendedor_uuid: '',
            notificacoes: {}
        };
        this.messages_view = React.createRef();

    }



    get_mensagens(vendedor_uuid,first=false) {
        this.setState({ loading: true, vendedor_uuid });
        console.log(this.props.token);
        fetch(`${URL}api/vendedor/get_mensagens_vendedor/${vendedor_uuid}?empresa_id=${this.props.empresa_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.setState({
                        mensagens: resp.mensagens,
                    },()=>{
                        if(first==true){
                            console.log('hi voce conectou')
                            this.init_shared_worker();
                        }
                    });
                    

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        if (this.props.user.id != undefined) {
            this.setState({ name: this.props.user.name, email: this.props.user.email, email_set: true },()=>{
                console.log({ vendedor_name: this.state.name, vendedor_id: this.props.user.id, vendedor_email: this.state.email, status: 'init', vendedor_uuid: this.state.vendedor_uuid, join: true, vendedor_foto: this.props.user.foto,empresa_id:this.props.empresa_id })
            });
        }
        else {
            let name = localStorage.getItem('name');
            let email = localStorage.getItem('email');
            if (name != null && email != null) {
                this.setState({ name, email, email_set: true });
            }
        }
        var vendedor_uuid = localStorage.getItem('vendedor_uuid');
        if (vendedor_uuid == null) {
            vendedor_uuid = uuidv4();
            localStorage.setItem('vendedor_uuid', vendedor_uuid);
            // document.getElementById('finalizar_spinner').style.display = 'none';

        }
        this.get_mensagens(vendedor_uuid,true);

    }

    componentWillUnmount(){
        if(this.shared_worker!=null){
            this.shared_worker.terminate();
            this.shared_worker=null;
        }
        
    }

    // componentDidUpdate(props) {
    //     // console.log(props);
    //     if (this.props.user.id != props.user.id) {
    //         this.get_mensagens(this.state.vendedor_uuid);
    //         if (this.props.user.id != undefined) {
    //             this.setState({ name: this.props.user.name, email: this.props.user.email, email_set: true }, () => {
    //                 console.log(this.props.user);
    //                 this.shared_worker.postMessage({empresa_id:this.props.empresa_id, vendedor_name: this.state.name, vendedor_id: this.props.user.id, vendedor_email: this.state.email, status: 'init', vendedor_uuid: this.state.vendedor_uuid, join: true, vendedor_foto: this.props.user.foto, vendedor_role: this.props.user.role, email: this.props.user.email, token: this.props.token })
    //             });
    //         }
    //         else {
    //             let name = localStorage.getItem('name');
    //             let email = localStorage.getItem('email');
    //             if (name != null && email != null) {
    //                 this.setState({ name, email, email_set: true }, () => {
    //                     this.shared_worker.postMessage({empresa_id:this.props.empresa_id, vendedor_name: this.state.name, vendedor_id: this.props.user.id, vendedor_email: this.state.email, status: 'init', vendedor_uuid: this.state.vendedor_uuid, join: true, vendedor_foto: this.props.user.foto })
    //                 });
    //             }
    //             else {
    //                 this.setState({ name: '', email: '', email_set: false });
    //             }
    //         }

    //     }
    // }

    init_shared_worker() {

        this.shared_worker = new Worker(THIS_URL+'shared_socket.js');
        console.log('shared work init');
        this.shared_worker.onmessage = (e) => {
            let mensagens = this.state.mensagens;
            console.log(e.data);
            if (e.data.status == 'connect') {
                if (this.state.email_set == true) {
                    this.shared_worker.postMessage({ close_chat:this.state.close_chat, vendedor_name: this.state.name, vendedor_id: this.props.user.id, vendedor_email: this.state.email, status: 'init', vendedor_uuid: this.state.vendedor_uuid, join: true, vendedor_foto: this.props.user.foto,empresa_id:this.props.empresa_id })
                    this.setState({loading:false});
                }
            }
            else if (e.data.status == 'check_if_reload') {
                if (e.data.vendedor_id != this.props.user.id) {
                    if (e.data.vendedor_id == null) {
                        this.props.login({ user: {}, token: undefined });
                    }
                    else {
                        this.props.login({ user: { id: e.data.vendedor_id, name: e.data.vendedor_name, role: e.data.vendedor_role, email: e.data.email }, token: e.data.token });
                    }
                }

            }
            else if (e.data.status == 'msg_save') {
                for (let i = 0; i < mensagens.length; i++) {
                    if (mensagens[i].uuid == e.data.id_msg) {
                        mensagens[i].visto = false;
                    }
                }
            }
            else if (e.data.status == 'message_other_tab') {
                mensagens.push({
                    uuid: e.data.id_msg,
                    message: e.data.msg,
                    vendedor_id: this.props.user.id == undefined ? null : this.props.user.id,
                    vendedor: this.props.user.id == undefined ? null : this.props.user,
                    vendedor_email: this.state.email,
                    vendedor_name: this.state.name,
                    vendedor_uuid: this.state.vendedor_uuid,
                    empresa_id: e.data.empresa_id,

                    atendente_id: null,
                    atendente: null,
                });
                // $('#body_msg').append(template_send.replace(/%MSG%/g, e.data.msg).replace(/%ID%/g, e.data.id_msg));
            }
            else if (e.data.status == 'msg_receive') {
                mensagens.push({
                    uuid: e.data.id_msg,
                    message: e.data.msg,
                    vendedor_id: this.props.user.id == undefined ? null : this.props.user.id,
                    vendedor: this.props.user.id == undefined ? null : this.props.user,
                    vendedor_email: this.state.email,
                    vendedor_name: this.state.name,
                    vendedor_uuid: this.state.vendedor_uuid,
                    atendente_id: e.data.atendente_id,
                    empresa_id: e.data.empresa_id,
                    atendente: { id: e.data.atendente_id, name: e.data.name },
                });
                // $('#body_msg').append(template_receive.replace(/%MSG%/g, e.data.msg).replace(/%ID%/g, e.data.id_msg).replace(/%NAME%/g, e.data.name));
                // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);

                if (this.state.close_chat == false) {
                    this.shared_worker.postMessage({ status: 'visto', id_msg: e.data.id_msg, vendedor_id: this.props.user.id, vendedor_uuid: this.state.vendedor_uuid })
                }
                else {
                    let notificacoes = this.state.notificacoes;
                    notificacoes[e.data.id_msg] = {
                        msg: e.data.msg,
                        name: e.data.name
                    };
                    this.setState({ notificacoes });
                    setTimeout(() => {
                        let notificacoes = this.state.notificacoes;
                        delete (notificacoes[e.data.id_msg]);
                        this.setState({ notificacoes });
                    }, 4000);
                }
            }
            else if (e.data.status == 'msg_vista') {
                for (let i = 0; i < mensagens.length; i++) {
                    mensagens[i].visto = true;
                    if (mensagens[i].uuid == e.data.id_msg) {
                        break;
                    }
                }
            }
            this.setState({ mensagens }, () => {
                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
            });
        }

    }



    render() {
        let mensagens = this.state.mensagens;

        return (
            <div style={{
                background: 'white', height: '440px', width: '300px',
                position: 'fixed',
                bottom: this.state.close_chat == true ? '-400px' : 0,
                right: '15px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px',
                zIndex: 1000, wordBreak: 'break-word', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }}
                id="chat_father">

                <a id="close_chat" onClick={() => {
                    if (!this.state.close_chat == false && this.state.loading==false) {
                        console.log({ status: 'visto', vendedor_id: this.props.user.id, vendedor_uuid: this.state.vendedor_uuid })
                        this.shared_worker.postMessage({ status: 'visto', vendedor_id: this.props.user.id, vendedor_uuid: this.state.vendedor_uuid });

                    }
                    this.setState({ close_chat: !this.state.close_chat });
                }}
                    style={{ backgroundColor: '#222', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                    <p style={{ margin: 0, color: 'white', textAlign: 'center' }}>Fale conosco</p>
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                        position: 'absolute',
                        right: '10px',
                        color: 'white',
                        fontSize: '2rem'
                    }}>
                        <span aria-hidden="true">{this.state.close_chat == false && <i className="fas fa-times"></i>}</span>
                    </button>
                </a>
                <div style={{ height: '330px', padding: 0, margin: 0 }} className="overflow-auto" id="body_msg" ref={this.messages_view}>
                    {mensagens.map((item, id) => (

                        <div key={id}>

                            {item.atendente_id == null && <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                <div style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }} >
                                    <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem', whiteSpace: 'pre-line' }}>{item.message}</p>
                                    <i className={item.visto == undefined ? "far fa-clock" : (item.visto == false ? "fas fa-check" : "fas fa-check-double")} style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>
                                </div>
                            </div>}
                            {item.atendente_id != null && <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1rem' }}>{item.atendente.name}</p>
                                    <p style={{ marginBottom: 0, color: 'black', whiteSpace: 'pre-line' }}>{item.message}</p>
                                </div>
                            </div>}
                        </div>
                    ))}

                </div>
                <div style={{ position: 'fixed', bottom: '50px', right: '10px', zIndex: 10000000, width: '300px', display: 'flex', flexDirection: 'column-reverse', whiteSpace: 'pre-wrap' }}
                    id="msg_notifi">
                    {Object.values(this.state.notificacoes).map((item, id) => (
                        <div key={id} style={{ background: 'green' }} className="alert alert-success bg-success text-white border-0 alert-dismissible fade show" role="alert">
                            <button onClick={(e) => {
                                let notificacoes = {...this.state.notificacoes};
                                delete (notificacoes[item.id]);
                                this.setState({ notificacoes });
                                e.target.parentElement.parentElement.remove();
                            }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <strong>{item.name}</strong>
                            <p style={{ margin: 0, color: 'white' }}>{item.msg}</p></div>
                    ))}

                </div>
                <div
                    style={{ height: '70px', backgroundColor: 'rgba(255,255,255,0.5)', borderTop: '1px solid lightgrey', padding: '5px', display: 'flex' }}>
                    <textarea value={this.state.mensagem} onChange={(e) => {
                        this.setState({ mensagem: e.target.value });
                    }} onKeyPress={(e) => {
                        console.log(e.key);
                        if (e.key == 'Enter' && this.state.mensagem != '' && this.state.mensagem.replace(/ /g, '') != '') {
                            e.preventDefault();
                            let uid_msg = uuidv4();
                            this.shared_worker.postMessage({
                                vendedor_uuid: this.state.vendedor_uuid, id_msg: uid_msg,
                                vendedor_name: this.state.name, vendedor_id: this.props.user.id,
                                vendedor_email: this.state.email, msg: this.state.mensagem,
                                status: 'send_msg', vendedor_foto: this.props.user.foto
                            });
                            let mensagens = this.state.mensagens;
                            mensagens.push({
                                uuid: uid_msg,
                                atendente_id: null,
                                atendente: null,
                                vendedor_uuid: this.state.vendedor_uuid,
                                vendedor_name: this.state.name, vendedor_id: this.props.user.id,
                                vendedor_email: this.state.email, message: this.state.mensagem,
                            });
                            this.setState({ mensagens, mensagem: '' }, () => {
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                            });

                        }
                    }} name="msg" placeholder="Mensagem..." className="form-control" style={{ resize: 'none', height: '100%', minHeight: 0 }}></textarea>
                    <div onClick={() => {
                        if (this.state.mensagem != '' && this.state.mensagem.replace(/ /g, '') != '') {

                            let uid_msg = uuidv4();
                            this.shared_worker.postMessage({
                                vendedor_uuid: this.state.vendedor_uuid, id_msg: uid_msg,
                                vendedor_name: this.state.name, vendedor_id: this.props.user.id,
                                vendedor_email: this.state.email, msg: this.state.mensagem,
                                status: 'send_msg', vendedor_foto: this.props.user.foto
                            });
                            let mensagens = this.state.mensagens;
                            mensagens.push({
                                uuid: uid_msg,
                                atendente_id: null,
                                atendente: null,
                                vendedor_uuid: this.state.vendedor_uuid,
                                vendedor_name: this.state.name, vendedor_id: this.props.user.id,
                                vendedor_email: this.state.email, message: this.state.mensagem,
                            });
                            this.setState({ mensagens, mensagem: '' }, () => {
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                            });
                        }
                    }} className="send_msg"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}>
                        <i className="fas fa-paper-plane" style={{ fontSize: '1rem' }}></i>

                    </div>
                </div>
                {this.state.email_set == false && <div id="chat_dados"
                    style={{ position: 'absolute', top: '40px', left: 0, right: 0, bottom: 0, display: 'block', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255,255,255,1)', padding: '15px', paddingTop: '30px' }}>

                    <div className="form-row">
                        <div className="col-sm-12 mb-sm-0">
                            <label className="font-size-md text-dark font-weight-semibold mb-1">Nome
                    <span className="text-danger">*</span></label>
                            <input className="form-control" type="text" placeholder="Nome" required value={this.state.name} onChange={(e) => {
                                this.setState({ name: e.target.value });
                            }} />
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="col-sm-12 mb-sm-0">
                            <label className="font-size-md text-dark font-weight-semibold mb-1">E-mail
                    <span className="text-danger">*</span></label>
                            <input className="form-control" type="text" value={this.state.email} onChange={(e) => {
                                this.setState({ email: e.target.value })
                            }} name="email" placeholder="E-mail" required />

                        </div>

                    </div>
                    <button onClick={() => {
                        if (this.state.email != '' && this.state.name != '') {
                            localStorage.setItem('email', this.state.email);
                            localStorage.setItem('name', this.state.name);

                            this.setState({ email_set: true });
                            this.shared_worker.postMessage({ vendedor_name: this.state.name, vendedor_id: this.props.user.id, vendedor_email: this.state.email, status: 'init', vendedor_uuid: this.state.vendedor_uuid, join: true, vendedor_foto: this.props.user.foto })

                        }
                    }} type="button" id="entrar" className="btn btn-primary btn-block font-size-lg">Entrar na conversa
                    </button>
                </div>}

                <div hidden={this.state.loading == false} id="finalizar_spinner"
                    style={{ position: 'absolute', top: '40px', left: 0, right: 0, bottom: 0,display:'flex',  justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                    <div style={{ display: 'block' }} className="spinner-border text-primary" role="status">
                    </div>
                </div>

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.AppReducer.token,
    user: state.AppReducer.user,
})

export default connect(mapStateToProps, { logout, login })(ChatCliente);