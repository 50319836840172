
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs } from 'react-bootstrap';

// users
import moment from 'moment';
import { URL } from '../../../../variables';
import { maskPrice } from '../../../Auxiliar/Masks';
import Modal from 'react-bootstrap/Modal';
import ReactTooltip from 'react-tooltip';


class VerVendedor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            msgErro: '',
            telefones: [],
            categorias: [],
            vendedor: {},

            vendedor_name: '',
            loading_search_vendedor: false,
            vendedors: [],
            vendedor_selecionado: {},
            show_autocomplete_vendedor: false,
            modal_open: false,

            loading_save: false,
            msgErroResposta: '',
            cliente_selecionado: {},

            clientes: []

        };
    }

    get_vendedor_search(search) {

        if (this.state.loading_search_vendedor == false) {
            this.setState({ loading_search_vendedor: true });
        }
        fetch(`${URL}api/vendedor_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_vendedor: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_vendedor: false, vendedors: resp.users });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_vendedor: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_vendedor: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar() {

        this.setState({ loading_save: true, msgErroResposta: '' });
        fetch(`${URL}api/inativos/alerar_cliente`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                vendedor: true,
                cliente_id: this.state.cliente_selecionado.id,
                vendedor_id_atual:this.state.vendedor.id,
                vendedor_id: Object.values(this.state.vendedor_selecionado).length > 0 ? Object.values(this.state.vendedor_selecionado)[0].id : null,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErroResposta: erro }, () => {

                    });
                }
                else {

                    this.setState({ loading_save: false, clientes:resp.cadastros,vendedor:resp.vendedor, modal_open: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErroResposta: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErroResposta: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }



    componentDidMount() {
        this.props.mudarTitle('Vendedor Inativo');

        this.get_vendedor(this.props.match.params.id);
    }

    get_vendedor(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/inativos/get_vendedor/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);

                    this.setState({ loading_screen: false,  vendedor: resp.vendedor,clientes:resp.cadastros });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    select_vendedor(item) {
        let users = {};
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ vendedor_name: '', show_autocomplete_vendedor: false, vendedor_selecionado: users })
    }


    render() {
        let vendedor = this.state.vendedor;

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="float-right">
                                <Link to={"/usuarios_inativos/"}><span
                                    className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                            </div>

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 d-flex">
                                        {/* <div style={{ height: 130, border: '1px solid lightgrey', borderRadius: 1000, marginRight: 15, width: 130, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                            <img src={URL + empresa.imagem} alt="img" class="gallery-thumb-img" style={{ height: 100, borderRadius: 0, width: 100, objectFit: 'contain' }} />
                                        </div> */}

                                        <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                            <div>

                                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{vendedor.name}</h4>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div className="row mb-2">

                                    <div className="col-12 pt-4 pb-2">
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Vendedor Associado:</b> {vendedor.name}</p>}

                                    </div>
                                    <div className="col-12 pb-2">
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone(s) Vendedor Associado:</b> {vendedor.telefones.map((item, id) => <span key={id}><br />{item}</span>)}</p>}

                                    </div>
                                    <div className="col-12 pb-2" >
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>CPF/CNPJ Vendedor Associado:</b> {vendedor.cnpj}</p>}

                                    </div>
                                    <div className="col-12 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>
                                        <p style={{ fontSize: '1rem', marginBottom: '.25rem', color: 'black' }}><b style={{ fontWeight: 'bold' }}>Endereço Vendedor:</b><br />{vendedor.endereco != null ? <a href={'https://www.google.com.br/maps/place/' + vendedor.endereco.rua.replace(/ /g, '+') + ',+' + vendedor.endereco.numero + '+-+' + vendedor.endereco.bairro.replace(/ /g, '+') + ',+' + vendedor.endereco.cidade.replace(/ /g, '+') + '+-+' + vendedor.endereco.estado.replace(/ /g, '+')}
                                            target="_blank" >{vendedor.endereco.rua}, {vendedor.endereco.numero} - {vendedor.endereco.bairro}, {vendedor.endereco.cidade} - {vendedor.endereco.estado}
                                        </a> : '-'}</p>
                                    </div>

                                    <div className="col-12 pb-4 pt-4" >

                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Clientes na Carteira:</b></p>                        <div className="row mb-2">
                                            <div className="col-12 col-md-6">

                                            </div>

                                            {/* <div className="col-12 col-md-6 d-flex justify-content-end">
                                <Link to={"/empresas/vendas/criar/" + this.props.empresa_id}><span
                                    className="btn btn-primary waves-effect waves-light" >Adicionar produtos</span></Link>
                            </div> */}
                                        </div>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Nome</th>
                                                            <th  >E-mail</th>
                                                            <th  >CPF/CNPJ</th>

                                                            <th  >Endereço Principal</th>

                                                            <th  >Ações</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.clientes.map((item, id) => (

                                                            <tr key={id}>

                                                                <th>
                                                                    {item.name}
                                                                </th>
                                                                <td>{item.email}</td>
                                                                <td>{item.cnpj}</td>

                                                                <td>{item.endereco != null ? <a href={"https://www.google.com.br/maps/place/" + item.endereco.rua.replace(/ /g, '+') + ",+" + item.endereco.numero + '+-+' + item.endereco.bairro.replace(/ /g, '+') + ',+' + item.endereco.cidade.replace(/ /g, '+') + '+-+' + item.endereco.estado.replace(/ /g, '+')}
                                                                    target="_blank"
                                                                    style={{ fontSize: '1rem', marginBottom: 0 }}>{item.endereco.rua}, {item.endereco.numero} - {item.endereco.bairro}
                                                                    <br />
                                                                    {item.endereco.cidade} - {item.endereco.estado}, CEP: {item.endereco.cep}
                                                                </a> : '-'}</td>

                                                                <td>
                                                                    <ReactTooltip />

                                                                    <button type="button" onClick={()=>{
                                                                        
                                                                        this.setState({modal_open:true,cliente_selecionado:item});
                                                                    }} data-toggle="tooltip" data-tip="Alterar Cliente" className="btn btn-success btn-outline btn-circle m-r-5" ><span

                                                                    ><i className="fas fa-exchange-alt"></i></span></button>

                                                                    {/* <Link to={'/empresas/produtos/editar/' + item.id}><span className="btn btn-info btn-outline btn-circle m-r-5"
                                                        data-toggle="tooltip"
                                                    ><i className="ti-pencil-alt"></i></span></Link>
                                                    <button onClick={() => {
                                                        this.setState({ show_warning: true, produto_to_delete: item.id });
                                                    }}
                                                        className="destroy_it btn btn-danger btn-outline btn-circle m-r-5"><i
                                                            className="ti-trash"></i></button> */}
                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {this.state.clientes.length == 0 && <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhum na carteira deste vendedor</p>

                                            </div>
                                        </div>}

                                    </div>


                                </div>

                                <Modal
                                    show={this.state.modal_open == true}
                                    onHide={() => this.setState({ modal_open: false })}
                                >
                                    <Modal.Header closeButton><Modal.Title>Vendedor Associado</Modal.Title></Modal.Header>



                                    <Modal.Body>

                                        <div>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-center ">
                                                    <p style={{ color: 'black', textarea: 'center', fontWeight: 400, fontSize: '1rem' }}>Altere o vendedor que está associado a este cliente
                                </p>

                                                </div>

                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 pt-2 pb-2">
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Cliente:</b> {this.state.cliente_selecionado.name}</p>}

                                                </div>
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Vendedor</label>
                                                <div className="col-sm-12">
                                                    <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_vendedor: true })} className="form-control" value={this.state.vendedor_name} placeholder="Nome do vendedor" type="text" onChange={(e) => { this.get_vendedor_search(e.target.value); this.setState({ vendedor_name: e.target.value }) }} />
                                                    {this.state.show_autocomplete_vendedor == true && this.state.vendedor_name != '' && <div className="overflow-auto" style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto' }}>
                                                        {this.state.loading_search_vendedor == false && this.state.vendedors.map((item, id) => (
                                                            <div key={id} onClick={() => this.select_vendedor(item)} className="opcao_autocomplete">
                                                                {item.name}
                                                            </div>
                                                        ))}
                                                        {this.state.loading_search_vendedor == false && this.state.vendedors.length == 0 &&
                                                            <div className="opcao_autocomplete">
                                                                Nenhum vendedor encontrado com este termo
                                                        </div>
                                                        }
                                                        {this.state.loading_search_vendedor == true &&
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                <div className="spinner-border text-primary" role="status"  >
                                                                    <span className="sr-only">Loading...</span>
                                                                </div></div>}
                                                    </div>}
                                                </div>


                                            </div>

                                            {Object.values(this.state.vendedor_selecionado).length != 0 && <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Vendedor Selecionado:</label>

                                                {Object.values(this.state.vendedor_selecionado).map((item, id) => (
                                                    <div key={id} className="col-sm-12 col-12 col-md-12 col-lg-12" style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}>
                                                        <span>{item.name}</span>
                                                        <button onClick={() => {
                                                            let users = { ...this.state.vendedor_selecionado };
                                                            delete (users[item.id]);
                                                            this.setState({ vendedor_selecionado: users });
                                                        }}
                                                            className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                                className="ti-trash" style={{ color: 'red' }}></i></button>
                                                    </div>
                                                ))}

                                            </div>}


                                            <p style={{ color: 'red' }}>{this.state.msgErroResposta}</p>
                                        </div>

                                    </Modal.Body>
                                    <Modal.Footer>


                                        {this.state.loading_save == false && <button onClick={() => {

                                            // this.enviar_resposta();
                                            this.salvar();
                                        }} type="button" className="btn btn-success btn-lg waves-effect waves-light">
                                            <span>Salvar</span>
                                            <i className="mdi mdi-content-save ml-2"></i>
                                        </button>}
                                        {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div></div>}
                                    </Modal.Footer>


                                </Modal>


                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }



}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(VerVendedor);


