
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs } from 'react-bootstrap';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import Produtos from './Produtos/Produtos';
import Informacoes from './Informacoes';
import { maskPrice } from '../../Auxiliar/Masks';
import Gerentes from './Gerentes/Gerentes';
import ChatCliente from './ChatCliente';
import Vendas from './Vendas/Vendas';
import Simulacoes from './Simulacoes/Simulacoes';


class VerEmpresa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/empresas',
            loading_screen: true,
            empresa: {},
            msgErro: '',
            tab: 0,
            telefones: [],
            categorias: []
        };
    }






    componentDidMount() {
        this.setState({ tab: this.props.match.params.indice });
        this.get_empresa(this.props.match.params.id);
    }

    get_empresa(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/minha_empresa/get_empresa/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.props.mudarTitle(resp.empresa.nome);

                    this.setState({ empresa: resp.empresa, loading_screen: false, telefones: resp.telefones, categorias: resp.categorias });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    render() {
        console.log('hiiii')
        let empresa = this.state.empresa;
        let telefones = this.state.telefones;
        let categorias = this.state.categorias;

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="float-right">
                                <Link to={"/empresas"}><span
                                    className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                            </div>

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 ">
                                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{empresa.nome}</h4>
                                        <p style={{ fontSize: '1rem', color: 'black' }}>{empresa.razao_social}</p>
                                    </div>


                                </div>
                                <div className="row mb-2">
                                    <div className="col-12 ">
                                        <div >
                                            <hr />
                                            <div style={{ height: 200, display: "flex", justifyContent: "center" }}>
                                                <img src={URL + empresa.imagem} alt="img" className="gallery-thumb-img" style={{ height: 200, objectFit: 'contain' }} />
                                            </div>
                                            <hr />
                                        </div>
                                        {empresa.dono != null && <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Dono:</b> {empresa.dono.name}</p>}

                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>CNPJ:</b> {empresa.cnpj}</p>
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Endereço:</b><br /><a href={'https://www.google.com.br/maps/place/' + empresa.rua.replace(/ /g, '+') + ',+' + empresa.numero + '+-+' + empresa.bairro.replace(/ /g, '+') + ',+' + empresa.cidade.replace(/ /g, '+') + '+-+' + empresa.estado.replace(/ /g, '+')}
                                            target="_blank" >{empresa.rua}, {empresa.numero} - {empresa.bairro}, {empresa.cidade} - {empresa.estado}
                                        </a></p>
                                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Preço por Km:</b> {maskPrice(parseFloat(empresa.preco_km).toFixed(2))}</p>
                                        {<p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Tipo de Comissão:</b> {empresa.tipo_comissao == 'F' ? 'Comissão Fixa' : 'Comissão por produto'}</p>}

                                        {empresa.tipo_comissao == 'F' && <p style={{ fontSize: '1rem', color: 'black' }}><b style={{ fontWeight: 'bold' }}>Valor da Comissão:</b> {maskPrice(parseFloat(empresa.comissao).toFixed(2))}</p>}

                                        <div style={{ marginBottom: '1rem' }}><p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone(s):</b> <br /></p>
                                            {telefones.map((item, id) => (
                                                <div key={id}>
                                                    {item}
                                                    <br />
                                                </div>
                                            ))}
                                        </div>

                                        <div style={{ marginBottom: '1rem' }}><p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Categoria(s):</b> <br />
                                        </p>

                                            {categorias.map((item, id) => (
                                                <div key={id}>
                                                    {item.nome}
                                                    <br />
                                                </div>
                                            ))}
                                        </div>


                                    </div>


                                </div>
                                <Produtos empresa_id={empresa.id} />
                                <Gerentes empresa_id={empresa.id} />
                                {/* <ChatCliente empresa_id={empresa.id} /> */}
                                <Vendas empresa_id={empresa.id} />
                                <Simulacoes empresa_id={empresa.id} />
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(VerEmpresa);


