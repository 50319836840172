import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';
import Chart from "react-apexcharts";
import moment from 'moment';
import 'moment/locale/pt-br';
import { maskPrice } from '../../Home/Auxiliar/Masks';

class lineareachart extends Component {
    constructor(props) {
        super(props);
        let values=[];
        let keys=[];
        for(let i=0;i<Object.keys(this.props.values).length;i++){
            values.push(parseFloat(Object.values(this.props.values)[i]).toFixed(2));
            keys.push(moment(Object.keys(this.props.values)[i],'YYYY-MM').locale('pt-br').format('MMM/YYYY'))
        }
        this.state = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: keys
                }
            },
            series: [
                {
                    name: "Vendas Totais",
                    data: values
                }
            ]
        };
    }

    render() {
       
        return (
            <React.Fragment>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height="400"
                    // width="500"
                />
            </React.Fragment>
        );
    }
}

export default lineareachart;