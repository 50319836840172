
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// users
import moment from 'moment';
import { URL } from '../../../../variables';
import ReactPaginate from 'react-paginate';
import { maskPrice } from '../../../Auxiliar/Masks';
import ReactTooltip from 'react-tooltip';

class Simulacoes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            vendas: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            produto_to_delete: '',
        };
    }






    componentDidMount() {

        this.get_vendas(this.state.page, this.props.empresa_id);
    }

    get_vendas(page, id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_simulacoes?page=${page}&id=${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ vendas: resp.vendas, empresa: resp.empresa, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    delete_produto(id) {

        fetch(`${URL}api/delete_produto/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_vendas(this.state.page, this.props.empresa_id);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }







    render() {
        return (
            <div className="row">

                <div className="col-12">
                    <br />


                    {/* <hr /> */}
                    {this.state.loading_screen == false && <div>

                        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Simulações de Venda:</b></p>                        <div className="row mb-2">
                            <div className="col-12 col-md-6">

                            </div>

                            {/* <div className="col-12 col-md-6 d-flex justify-content-end">
                                <Link to={"/empresas/vendas/criar/" + this.props.empresa_id}><span
                                    className="btn btn-primary waves-effect waves-light" >Adicionar produtos</span></Link>
                            </div> */}
                        </div>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>

                                            <th>Nº pedido</th>
                                            <th>Cliente</th>
                                            <th  >Endereço</th>
                                            <th  >Serviço</th>

                                            <th  >Total</th>
                                            <th  >Status</th>

                                            <th  >Ações</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.vendas.map((item, id) => (

                                            <tr key={id}>

                                                <th>
                                                    {item.id}
                                                </th>
                                                <td>{item.cliente.name}</td>
                                                <td><a href={"https://www.google.com.br/maps/place/" + item.rua.replace(/ /g, '+') + ",+" + item.numero + '+-+' + item.bairro.replace(/ /g, '+') + ',+' + item.cidade.replace(/ /g, '+') + '+-+' + item.estado.replace(/ /g, '+')}
                                                    target="_blank"
                                                    style={{ fontSize: '1rem', marginBottom: 0 }}>{item.rua}, {item.numero} - {item.bairro}
                                                    <br />
                                                    {item.cidade} - {item.estado}, CEP: {item.cep}
                                                </a></td>
                                                <td>{item.entrega==true?'Entrega':'Retirada'}</td>
                                                <td>{maskPrice(parseFloat(item.valor).toFixed(2))}</td>

                                                <td>
                                                    { <span style={{ color: 'green' }}>Pedido foi finalizado (Simulado)</span>}


                                                </td>
                                                <td>
                                                <ReactTooltip />

                                                    <Link data-tip="Visualizar Simulação" to={'/empresas/simulacoes/' + item.id}><span className="btn btn-success btn-outline btn-circle m-r-5"
                                                        data-toggle="tooltip"
                                                    ><i className="mdi mdi-eye"></i></span></Link>

                                                    {/* <Link to={'/empresas/produtos/editar/' + item.id}><span className="btn btn-info btn-outline btn-circle m-r-5"
                                                        data-toggle="tooltip"
                                                    ><i className="ti-pencil-alt"></i></span></Link>
                                                    <button onClick={() => {
                                                        this.setState({ show_warning: true, produto_to_delete: item.id });
                                                    }}
                                                        className="destroy_it btn btn-danger btn-outline btn-circle m-r-5"><i
                                                            className="ti-trash"></i></button> */}
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.state.vendas.length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhuma simulação realizada</p>

                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <ReactPaginate
                                    previousLabel={'Anterior'}
                                    nextLabel={'Próxima'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.total_pages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClick.bind(this)}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    initialPage={this.state.page - 1}
                                />
                            </div>
                        </div>



                        {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                    </div>}

                    {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div>}
                    <SweetAlert
                        warning
                        title={"Atenção"}
                        onConfirm={() => {
                            this.delete_produto(this.state.produto_to_delete);
                        }}
                        onCancel={() => {
                            this.setState({ show_warning: false });
                        }}
                        show={this.state.show_warning}
                        confirmBtnText='Sim, desejo apagar'
                        cancelBtnText='Cancelar'
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        showCancel={true}
                    >
                        Ao deletar esta produto ele não estará mais disponível no sistema

                                </SweetAlert>
                    <SweetAlert
                        danger
                        title={"Erro"}
                        onConfirm={() => {
                            this.setState({ show_erro: false });
                        }}
                        show={this.state.show_erro}
                        confirmBtnText='OK'
                        confirmBtnBsStyle="primary"

                    >
                        {this.state.msgErro}

                    </SweetAlert>

                </div>
            </div>

        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_vendas(page.selected + 1, this.props.empresa_id);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(Simulacoes));


