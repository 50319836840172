import React, { Component } from 'react';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { URL } from '../../../variables';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import Modal from 'react-bootstrap/Modal';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        // overflowY: 'scroll'
    }
};
class ChamadosFechadosAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conversas: [],
            conversas_ids: [],

            loading: true,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            vendedor_to_delete: '',
            conversa_select: '',
            vendedor_select: '',
            protocolo_select: '',
            mensagem: '',
            empresa_id: null,
            empresa: {},
            modal_open: false,
            msgErroResposta: '',
            assunto: '',
            mensagem: '',
            loading_save: false,

            vendedor_name: '',
            loading_search_vendedor: false,
            vendedors: [],
            vendedor_selecionado: {},
            show_autocomplete_vendedor: false,

            assunto: '',
            mensagem_inicial: '',
            name_search: '',

            loading_finalizar: false,
            msgErroJustificativa: '',
            justificativa: '',
            modal_open_finalizar: false,
            show_sucesso: false

        };
        this.messages_view = React.createRef();

    }


    get_conversas() {
        this.setState({ loading: true });

        fetch(`${URL}api/get_conversas_vendedor_fechadas`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.setState({
                        conversas: resp.conversas,
                        conversas_ids: resp.conversas_ids,
                        empresa: resp.empresa,
                        loading: false
                    }, () => {
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }



    componentDidMount() {
        this.props.mudarTitle('Chamados Fechados');
       
            this.get_conversas();
    }




    render() {
        let conversas = this.state.conversas;
        if (this.state.name_search.trim().length > 0) {
            conversas = conversas.filter((item, id) => {
                return item.vendedor_name.toLowerCase().includes(this.state.name_search.toLowerCase()) || item.protocolo.toLowerCase().includes(this.state.name_search.toLowerCase());
            });
        }
        return (
            <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>

                <div className="container" style={{ padding: 0, margin: 0 }}>
                    <div className="float-right">

                        <Link to={"/chamados"}><span
                            className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                    </div>
                    <br />
                    <br />
                    <div>

                        <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>

                            <div style={{
                                margin: 15,
                                position: 'relative',
                                width: '100%',
                                marginTop: 0
                            }} >
                                {/* <h6>Chat de Atendimento</h6> */}
                                {(conversas.length > 0 || this.state.name_search != '') && <div className="row" style={{ borderRadius: 10, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.03)' }}>
                                    <div className="col-md-4 overflow-auto"
                                        style={{ position: 'relative', border: '1px solid white', padding: 0, height: '680px', background: 'white', borderRight: '1px solid lightgrey', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>

                                        <div id="conversas_head">
                                            <div style={{ position: 'relative', padding: 10, height: 60 }}>
                                                <div style={{ position: 'absolute', top: 21, left: 20 }}>
                                                    <i className="fas fa-search" style={{ fontSize: '1rem' }}></i>
                                                </div>
                                                <input autoComplete="off" onChange={(e) => this.setState({ name_search: e.target.value, conversa_select: '', vendedor_select: '', protocolo_select: '' })} value={this.state.name_search} name="msg" placeholder="Nome do vendedor ou Protocolo" className="form-control msg_input" style={{ resize: 'none', height: '100%', borderRadius: 50, paddingLeft: 40 }} />
                                            </div>
                                            {conversas.map((item, id) => (

                                                <div onClick={() => {
                                                    let conversas = [...this.state.conversas];
                                                    conversas[id].nao_vistas = 0;
                                                    this.setState({ conversa_select: item.id, conversas, vendedor_select: item.vendedor_id, protocolo_select: item.protocolo }, () => {
                                                        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                                    });


                                                }} key={id} className="chat"
                                                    style={{ background: this.state.conversa_select == item.id ? '#f2f2f2' : 'white', borderBottom: '1px solid grey', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ display: 'flex', margin: '5px' }} >
                                                        <div className="image mr-3"><img
                                                            src={(item.vendedor == null || item.vendedor.foto == null) ? URL + 'images/default_profile.jpg' : URL + 'storage/' + item.vendedor.foto}
                                                            alt="User image" className="rounded-circle" style={{ minWidth: '70px', height: '70px', width: '70px', objectFit: 'cover', border: '1px solid lightgrey' }} /></div>
                                                        <div>
                                                            <p style={{ fontWeight: 'bold', fontSize: '1rem', color: 'black', marginBottom: '5px' }}>{item.vendedor == null ? item.vendedor_name : item.vendedor.name}</p>
                                                            <span style={{ fontWeight: 'normal', fontSize: '0.9rem' }}>
                                                                {item.last_message}
                                                            </span>

                                                        </div>
                                                    </div>
                                                    <div style={{ marginRight: 5 }}>
                                                        {item.mensagens.length > 0 && moment(item.mensagens[item.mensagens.length - 1].created_at).format('DDMMYYYY') != moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(item.mensagens[item.mensagens.length - 1].created_at).format('DD/MM/YYYY')}</p>}
                                                        {item.mensagens.length > 0 && moment(item.mensagens[item.mensagens.length - 1].created_at).format('DDMMYYYY') == moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(item.mensagens[item.mensagens.length - 1].created_at).format('HH:mm')}</p>}
                                                        {item.mensagens.length == 0 && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment().format('HH:mm')}</p>}
                                                        <p style={{ color: "green", marginBottom: 0, textAlign: 'end' }}>{item.protocolo}</p>
                                                        {/* <div style={{ display: 'flex', margin: '5px', justifyContent: 'center', alignItems: 'center' }} >
                                                    <div hidden={item.nao_vistas == 0}
                                                        style={{ minWidth: '30px', backgroundColor: 'green', borderRadius: '100px', height: '30px', width: '30px', color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                        {item.nao_vistas}</div>
                                                </div> */}
                                                    </div>

                                                </div>
                                            ))}

                                        </div>

                                    </div>
                                    <div className="col-md-8 col-12" style={{ background: 'white', padding: 0, borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>

                                        <div style={{ height: '630px', width: '100%' }} className="overflow-auto" id="body_msg" ref={this.messages_view}>

                                            {conversas.map((item, id) => (


                                                <div key={id} hidden={this.state.conversa_select != item.id}>
 <div style={{ borderBottom: '1px solid lightgrey', padding: 10 }}>
                                                        <p style={{ marginBottom: 0, textAlign: 'center', fontWeight: 'bold', fontSize: '0.93rem', color: 'black' }}>Assunto: <span style={{ fontWeight: 'normal', marginBottom: 0 }}>{item.assunto}</span></p>
                                                    </div>
                                                    {item.mensagens.map((msg, id2) => (
                                                        <div key={id2}>
                                                            {(id2 == 0 || moment(item.mensagens[id2].created_at).format('YYYY-MM-DD') != moment(item.mensagens[id2 - 1].created_at).format('YYYY-MM-DD')) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <p style={{
                                                                    marginTop: 10,
                                                                    textAlign: 'center',
                                                                    marginBottom: 0,
                                                                    padding: '5px',
                                                                    border: '1px solid lightslategrey',
                                                                    color: 'darkslategrey',
                                                                    borderRadius: '10px',
                                                                    backgroundColor: 'aliceblue',
                                                                    marginTop: '10px',
                                                                    paddingLeft: '10px',
                                                                    paddingRight: '10px'
                                                                }}>{moment(item.mensagens[id2].created_at).format('DD/MM/YYYY')}</p>
                                                            </div>}
                                                            {msg.atendente_id == this.props.user.id && <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                                <div
                                                                    style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                                    <div
                                                                        style={{ backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }}>
                                                                        <p style={{ marginBottom: 0, color: 'black', marginRight: '0.5rem', padding: '5px', }}>
                                                                            {msg.message}</p>
                                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3 }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')} <i className={msg.visto == undefined ? 'far fa-clock' : (msg.visto == false ? "fas fa-check" : "fas fa-check-double")}
                                                                            style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i></p>

                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            {msg.atendente_id == null && <div
                                                                style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}
                                                                >
                                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1rem' }}>
                                                                        {msg.vendedor == null ? msg.vendedor_name : msg.vendedor.name}
                                                                    </p>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                                        <p style={{ marginBottom: 0, color: 'black' }}>{msg.message}</p>
                                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3 }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            {msg.atendente_id != null && msg.atendente_id != this.props.user.id && <div
                                                                style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}
                                                                >
                                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1rem' }}>
                                                                        {msg.atendente.name}</p>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                                        <p style={{ marginBottom: 0, color: 'black' }}>{msg.message}</p>
                                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3 }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>


                                    </div>
                                </div>}

                                {conversas.length == 0 && <div className="row d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <p style={{ textAlign: "center" }}>Nenhuma conversa iniciada</p>
                                </div>}
                                <div hidden={this.state.loading == false} id="finalizar_spinner"
                                    style={{ position: 'absolute', top: '0px', left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                                    <div style={{ display: 'block' }} className="spinner-border text-primary" role="status">
                                    </div>
                                </div>
                            </div>
                        </div >

                    </div>
                </div>
            </div>
        )
    }

    select_vendedor(item) {
        let users = {};
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ vendedor_name: '', show_autocomplete_vendedor: false, vendedor_selecionado: users })
    }
}

export const mapStateToProps = (state) => ({
    token: state.AppReducer.token,
    user: state.AppReducer.user,
})

export default connect(mapStateToProps, { logout, mudarTitle })(ChamadosFechadosAdmin);