import React from 'react';
import { URL } from '../variables';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/AppActions';
import logo from '../assets/images/hubi1.png';
import background from '../assets/images/posto.jpg';


class AlterarSenha extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            msgErro: '',
            password: '',
            redirect: false,
            path: '',
            loading_save: false,
            msgSucesso: '',
            password: '',
            confirm_password: '',
            token: '',
        }

    }

    recuperar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/alterar_senha`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: this.state.token,
                password: this.state.password,
                password_confirmation: this.state.confirm_password,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro, refresh: false, loadingMore: false });
                }
                else {
                    this.setState({ password: '',confirm_password:'', loading_save: false, msgSucesso: resp.msg });
                    // this.setState({ redirect: true, path: '/home', loading: false });
                    // if (resp.faculdade != null) {
                    //     this.setState({ faculdade: resp.faculdade, loading: false, msgErro: '' });
                    // }
                    // else {
                    //     this.setState({ redirect: true, path: '/', loading: false });
                    // }
                    // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
            });
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        this.setState({ token: params.token });
        // this.setState({ loading: true, msgErro: '' });
        // this.get_faculdade(params.slug);
    }





    render() {
        return (
            <div style={{ height: '100vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                    <span className="sr-only">Loading...</span>
                </div></div>}
                {!this.state.loading && <div className="row" style={{ backgroundColor: 'white' }}>
                    <div className="col-md-6 col-12 col-sm-12 col-lg-4 col-xl-4" style={{ margin: 0, padding: 0 }}>
                        <div className="card" style={{ height: '100vh', margin: 0 }}>
                            <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>


                                <div className="text-center">
                                    <a className="logo"><img src={logo}
                                        height="100" alt="logo" /></a>
                                </div>

                                <div style={{ padding: '1rem', paddingBottom: 0 }}>
                                    <h4 className="font-18 m-b-5 text-center">Recuperar Senha</h4>
                                    <p className="text-muted text-center">Escolha uma nova senha para sua conta.</p>
                                    <div hidden={this.state.msgSucesso == ''} className="alert alert-success" role="alert">
                                        {this.state.msgSucesso}
                                    </div>
                                    <form className="form-horizontal m-t-30" >

                                        <div className="form-group row" hidden={this.state.msgSucesso!=''}>
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Senha <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.password} placeholder="Senha" type="password" onChange={(e) => this.setState({ password: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row" hidden={this.state.msgSucesso!=''}>
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Confirmar Senha <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.confirm_password} placeholder="Confirmar Senha" type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} />

                                            </div>
                                        </div>



                                        <div className="form-group row ">
                                            <div className="col-sm-12">
                                                <span className="invalid-feedback" style={{ display: 'flex', justifyContent: 'center' }} role="alert">
                                                    <strong style={{ textAlign: 'center', fontSize: '0.8rem' }}>{this.state.msgErro}</strong>
                                                </span>
                                            </div>
                                            {this.state.loading_save == false && <div hidden={this.state.msgSucesso!=''} className="col-sm-12 text-right">
                                                <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', }} type="button" onClick={() => {
                                                    this.recuperar();
                                                }}>Alterar senha</button>

                                            </div>}
                                            {this.state.loading_save == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '15px' }}>
                                                <div className="spinner-border text-primary" role="status"  >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="form-group m-t-10 mb-0 row">
                                            <div className="col-12 m-t-20" style={{ textAlign: 'center' }}>
                                                <Link to='/login' className="color_link">
                                                    <i className="mdi mdi-login mr-1"></i>Alterou sua senha? Faça Login
                                                </Link>
                                                {/* <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm',$faculdade->slug) }}">
                                            <i className="mdi mdi-lock"></i>{{ __('Esqueceu sua senha?') }}
                                        </a>  */}
                                                {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-center mt-3">
                                    <p>© 2021 Hubi</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-6 col-12 col-lg-8 col-xl-8 d-none d-md-block" style={{ overflow: 'hidden', backgroundImage: `url(${background})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                    </div>
                </div>}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { login })(AlterarSenha);
