import React, { Component } from 'react';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../variables';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import ChatVendedor from './ChatVendedorFechado';
import { Tab, Tabs } from 'react-bootstrap';
import ChatVendedorFechado from './ChatVendedorFechado';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

class ChatsTabsFechado extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            vendedor_to_delete: '',
            conversa_select: '',
            mensagem: '',
            empresa_id: null,
            empresas: []
        };
        this.messages_view = React.createRef();

    }

    get_empresas() {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/minha_empresa/get_empresas`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ empresas: resp.empresas, loading_screen: false, empresa_id: resp.empresas.length == 0 ? null : resp.empresas[0].id });
                    // if (resp.processo != null) {



                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    componentDidMount() {
        this.props.mudarTitle('Chamados em Aberto');
        this.get_empresas();
    }




    render() {
        return (

            <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>

                <div  className="container" style={{padding:0,margin:0}}>
                    <div className="float-right">

                        <Link to={"/chamados"}><span
                            className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                    </div>
                    <br/>
                    <br/>

                    {/* <h6>Chat de Atendimento</h6> */}
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.tab}
                        onSelect={(k) => this.setState({ tab: k })}
                    >
                        {this.state.empresas.map((item, id) => {
                            return (
                                <Tab tabClassName={'tab'} key={id} eventKey={id} title={item.nome}>
                                    <ChatVendedorFechado empresa_id={item.id} />
                                </Tab>
                            );

                        })}

                    </Tabs>

                   

                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.AppReducer.token,
    user: state.AppReducer.user,
})

export default connect(mapStateToProps, { logout, mudarTitle })(ChatsTabsFechado);