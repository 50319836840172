
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../../../assets/images/users/user-4.jpg';
import moment from 'moment';
import { URL } from '../../../variables';

class Chamados extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            nao_iniciado: false,
            regras_processo: false,
            andamento: false,
            finalizada: false,
            loading_screen: true,
            chamados: 0
        };
    }





    componentDidMount() {
        this.props.mudarTitle('Empresas');
        this.get_chamados_abertos();
    }

    get_chamados_abertos() {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/minha_empresa/get_chamados_abertos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ chamados: resp.chamados, loading_screen: false });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    render() {
        return (
            <div>
                <div className="float-right">

                    <Link to={"/"}><span
                        className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                            style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                </div>
                <br />
                <br />

                {this.state.loading_screen == false && <div className="row">

                    {this.state.redirect == true && <Redirect to={this.state.path} />}


                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                        <Link to="/chamados_abertos"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                            <i className="mdi mdi-email-open-outline" style={{ fontSize: '1.8rem', marginRight: '1rem', color: '#1893d6' }}></i>
                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Chamados Abertos</p>
                            <div hidden={this.state.chamados == 0}
                            style={{marginLeft:10,fontSize:'1rem', minWidth: '30px', backgroundColor: 'green', borderRadius: '100px', height: '30px', padding:5, color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            {this.state.chamados>99?'99+':this.state.chamados}</div>
                        </div></Link>
                        
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                        <Link to="/chamados_fechados"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                            <i className="mdi mdi-email-lock" style={{ fontSize: '1.8rem', marginRight: '1rem', color: '#1893d6' }}></i>
                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Chamados Fechados</p>
                        </div></Link>
                    </div>
                    {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                        <Link to="/chamados_fechados"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                            <i className="mdi mdi-email-lock" style={{ fontSize: '1.8rem', marginRight: '1rem', color: '#1893d6' }}></i>
                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Buscar Chamado</p>
                        </div></Link>
                    </div> */}
                    {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                    <Link to="/folhas"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="far fa-file" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#1893d6' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Folhas</p>
                    </div></Link>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                    <Link to="/tutoriais"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-chalkboard-teacher" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#1893d6' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Tutoriais</p>
                    </div></Link>
                </div> */}
                    {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/produtos"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-wine-bottle" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#1893d6' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Produtos</p>
                    </div></Link>
                </div> */}
                    {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/banners"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-images" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#1893d6' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Banners</p>
                    </div></Link>
                </div> */}
                </div>}

                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(Chamados);


