import React from 'react';
import { URL } from '../variables';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/AppActions';
import LOGO from '../assets/images/hubi1.png';
import background from '../assets/images/posto.jpg';
import amex from '../assets/images/cartoes/amex.png';
import elo from '../assets/images/cartoes/elo.png';
import hypercard from '../assets/images/cartoes/hypercard.png';
import mastercard from '../assets/images/cartoes/mastercard.png';
import visa from '../assets/images/cartoes/visa.png';
import creditCardType from 'credit-card-type';
import { maskNumber, maskCpfCnpj, maskCartaoCredito, maskValidadeCartao, maskPrice } from './Auxiliar/Masks';
import moment from 'moment';

class VisualizarSimulacao extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            msgErro: '',
            email: '',
            password: '',
            redirect: false,
            path: '/',
            loading_save: false,
            numero_cartao: '',
            cvv: '',
            validade: '',
            cpf_cnpj: '',
            nome: '',
            types: [],
            pedido: {}
        }

    }

    salvar() {
        this.setState({ loading_save: true });
        fetch(`${URL}api/auth/login_painel`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.setState({ loading_save: false });
                    // this.setState({ redirect: true, path: '/home', loading: false });
                    // if (resp.faculdade != null) {
                    //     this.setState({ faculdade: resp.faculdade, loading: false, msgErro: '' });
                    // }
                    // else {
                    //     this.setState({ redirect: true, path: '/', loading: false });
                    // }
                    // this.setState({ loading: false, refresh: false, loadingMore: false, msgErro: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false });
            });
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        // this.setState({ loading: true, msgErro: '' });
        // this.get_faculdade(params.slug);
        this.get_venda(params.id);
    }

    changeEmail(event) {
        let text = event.target.value
        let final_num = '';
        // if(text.length>0 && isNaN(text[0])){
        final_num = text;
        this.setState({ email: final_num })



    }

    get_venda(id) {

        if (this.state.loading == false) {
            this.setState({ loading: true });
        }
        fetch(`${URL}api/pagamento/get_simulacao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    if (resp.venda == null) {
                        this.setState({ pedido: null, msgErro: 'Venda Inválida', loading: false });
                    }
                    else {
                        this.setState({ loading: false, pedido: resp.venda });
                    }
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    changePassword(event) {
        this.setState({ password: event.target.value })
    }

    calcular_juros_produto(produto, dia_pagamento) {

        let valor = produto.preco * produto.quantidade;
        for (let i = 0; i < dia_pagamento; i++) {
            valor = valor * (1 + parseFloat(produto.percent_juros));
        }

        return valor;
    }

    render() {
        let logo = '';
        let brand = '';
        if (this.state.types.length == 1) {
            brand = this.state.types[0].niceType.toUpperCase();
            console.log(brand);
            if (brand.includes('VISA')) {
                logo = visa;
            }
            else if (brand.includes('MASTER')) {
                logo = mastercard;
            }
            else if (brand.includes('HYPER')) {
                logo = hypercard;
            }
            else if (brand.includes('AME')) {
                logo = amex;
            }
            else if (brand.includes('ELO')) {
                logo = elo;
            }

        }
        let pedido=this.state.pedido;
        return (
            <div style={{ minHeight: '100vh' }}>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {/* {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                </div></div>} */}
                {<div className="row" style={{ backgroundColor: 'white', margin: 0 }}>

                    <div className="col-md-12 col-sm-12 col-12 col-lg-12 col-xl-12 d-block" style={{ overflow: 'hidden', backgroundImage: `url(${background})`, backgroundSize: 'cover', padding: 0, margin: 0, minHeight: '100vh' }}>
                        <div className="row" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
                            <div className="col-md-10 col-12 col-sm-12 col-lg-8 col-xl-6" style={{ margin: 0, padding: 0 }}>
                                <div className="card" style={{ margin: 0 }}>
                                    {this.state.loading == true && <div style={{ minHeight: 300, margin: 10, paddingTop: 100 }}>
                                        <h5 style={{ textAlign: 'center', color: 'black' }}>Carregando informações sobre o pagamento</h5>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div>

                                    </div>}

                                    {this.state.loading == false && this.state.pedido!=null && <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>


                                        <div className="text-center">
                                            <a className="logo"><img src={LOGO}
                                                height="50" alt="logo" /></a>
                                        </div>

                                        <div>
                                            <div className="row mb-2">
                                                <div className="col-12 d-flex">
                                                    {/* <div style={{ height: 130, border: '1px solid lightgrey', borderRadius: 1000, marginRight: 15, width: 130, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                            <img src={URL + empresa.imagem} alt="img" class="gallery-thumb-img" style={{ height: 100, borderRadius: 0, width: 100, objectFit: 'contain' }} />
                                        </div> */}

                                                    <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                                        <div>

                                                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Pedido #{pedido.id}</h4>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-12 pt-4 pb-2">
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Empresa:</b> {pedido.empresa.nome}</p>}

                                                </div>
                                                <div className="col-12  pb-2">
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone Empresa:</b> {pedido.empresa.telefone}</p>

                                                </div>
                                                <div className="col-12  pb-2">
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Forma de Pagamento:</b> {pedido.forma_pagamento}</p>

                                                </div>
                                                <div className="col-12  pb-2">
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Pagamento:</b> {pedido.dia_pagamento == 0 ? 'Á vista' : 'Em ' + pedido.dia_pagamento + ' dias'}</p>

                                                </div>
                                                <div className="col-12  pb-2">
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Subtotal:</b> {maskPrice(parseFloat(pedido.valor - pedido.frete).toFixed(2).toString())}</p>

                                                </div>
                                                {pedido.entrega == true && <div className="col-12  pb-2">
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Frete:</b> {maskPrice(parseFloat(pedido.frete).toFixed(2))}</p>

                                                </div>}
                                                {<div className="col-12  pb-2">
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Serviço:</b> {pedido.entrega == true ? 'Entrega' : 'Retirada'}</p>

                                                </div>}
                                                <div className="col-12  pb-2" >
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Total:</b> {maskPrice(parseFloat(pedido.valor).toFixed(2))}</p>

                                                </div>
                                                <div className="col-12  pb-4" style={{ borderBottom: '1px solid lightgrey' }}>
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Status: </b>
                                                        {<span style={{ color: 'green' }}>Pedido foi finalizado (Simulado)</span>}

                                                    </p>

                                                </div>


                                                <div className="col-12 pt-4 pb-2">
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Cliente:</b> {pedido.cliente.name}</p>}

                                                </div>
                                                <div className="col-12 pb-2">
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone(s) Cliente:</b> {pedido.cliente.telefones.map((item, id) => <span key={id}><br />{item}</span>)}</p>}

                                                </div>
                                                <div className="col-12 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>CPF/CNPJ Cliente:</b> {pedido.cliente.cnpj}</p>}

                                                </div>
                                                <div className="col-12 pt-4 pb-2">
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Vendedor:</b> {pedido.vendedor.name}</p>}

                                                </div>
                                                <div className="col-12 pb-2">
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>Telefone(s) Vendedor:</b> {pedido.vendedor.telefones.map((item, id) => <span key={id}><br />{item}</span>)}</p>}

                                                </div>
                                                {/* <div className="col-12 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>
                                                    {<p style={{ fontSize: '1rem', color: 'black', marginBottom: 0 }}><b style={{ fontWeight: 'bold' }}>CPF/CNPJ Vendedor:</b> {pedido.vendedor.cnpj}</p>}

                                                </div> */}
                                                <div className="col-12 pt-4 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>

                                                    <p style={{ fontSize: '1rem', marginBottom: '.25rem', color: 'black' }}><b style={{ fontWeight: 'bold' }}>{pedido.entrega == true ? 'Endereço de Entrega' : 'Endereço de Retirada'}:</b><br /><a href={'https://www.google.com.br/maps/place/' + pedido.rua.replace(/ /g, '+') + ',+' + pedido.numero + '+-+' + pedido.bairro.replace(/ /g, '+') + ',+' + pedido.cidade.replace(/ /g, '+') + '+-+' + pedido.estado.replace(/ /g, '+')}
                                                        target="_blank" >{pedido.rua}, {pedido.numero} - {pedido.bairro}, {pedido.cidade} - {pedido.estado}
                                                    </a></p>
                                                </div>
                                                <div className="col-12 pt-4 pb-4" style={{ borderBottom: '1px solid lightgrey' }}>

                                                    <p style={{ fontSize: '1rem', marginBottom: '.25rem', color: 'black' }}><b style={{ fontWeight: 'bold' }}>{'Endereço de Cobrança'}:</b><br /><a href={'https://www.google.com.br/maps/place/' + pedido.endereco_cobranca.rua.replace(/ /g, '+') + ',+' + pedido.endereco_cobranca.numero + '+-+' + pedido.endereco_cobranca.bairro.replace(/ /g, '+') + ',+' + pedido.endereco_cobranca.cidade.replace(/ /g, '+') + '+-+' + pedido.endereco_cobranca.estado.replace(/ /g, '+')}
                                                        target="_blank" >{pedido.endereco_cobranca.rua}, {pedido.endereco_cobranca.numero} - {pedido.endereco_cobranca.bairro}, {pedido.endereco_cobranca.cidade} - {pedido.endereco_cobranca.estado}
                                                    </a></p>
                                                </div>
                                                <div className="col-12 mt-4 mb-4">
                                                    <p style={{ fontSize: '1rem', color: 'black', marginBottom: '.25rem' }}><b style={{ fontWeight: 'bold' }}>Produtos:</b></p>
                                                </div>
                                                <div className="col-12">


                                                    <div className="table-rep-plugin">
                                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                            <table id="tech-companies-1" className="table table-striped">
                                                                <thead>
                                                                    <tr>

                                                                        <th>Nome</th>
                                                                        <th>Quantidade</th>

                                                                        <th>Preço</th>

                                                                        <th>Juros</th>
                                                                        <th>Total</th>

                                                                        <th >Estoque</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {pedido.produtos.map((item, id) => (

                                                                        <tr key={id}>

                                                                            <th>
                                                                                <img src={URL + item.imagem} style={{ height: 60, width: 60, objectFit: 'contain', marginRight: 10 }} />
                                                                                {item.nome}
                                                                            </th>
                                                                            <td>{item.quantidade} {item.unidade}</td>

                                                                            <td>{maskPrice(parseFloat(item.preco).toFixed(2))}</td>
                                                                            {/* <td>{maskPrice(parseFloat(item.preco * item.quantidade).toFixed(2))}</td> */}
                                                                            <td>{pedido.dia_pagamento == 0 ? maskPrice('0.00') : maskPrice(parseFloat(this.calcular_juros_produto(item, pedido.dia_pagamento) - item.preco * item.quantidade).toFixed(2))}</td>

                                                                            <td>{maskPrice(parseFloat(this.calcular_juros_produto(item, pedido.dia_pagamento)).toFixed(2))}</td>

                                                                            <td>{item.estoque} {item.unidade}</td>



                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {pedido.produtos.length == 0 && <div className="row w-100">
                                                        <div className="col-12">
                                                            <p style={{ textAlign: 'center' }}>Nenhum produto cadastrado</p>

                                                        </div>
                                                    </div>}
                                                </div>

                                            </div>



                                        </div>
                                        <hr />
                                        <div className="text-center mt-4">
                                            <p>© 2021 Hubi</p>
                                        </div>
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
    }
);

export default connect(mapsStateToProps, { login })(VisualizarSimulacao);
