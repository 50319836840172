
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { maskCnpj, maskCep, maskTelefone, maskPrice, maskPercent } from '../../Auxiliar/Masks';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class CriarEmpresa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/empresas',
            loading_screen: false,
            nome: '',
            cnpj: '',
            cep: '',
            rua: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            complemento: '',
            razao_social: '',
            imagem:'',
            imagem_path:'',
            preco_km: '',
            comissao: '',
            comissao_hubi:'',
            tipo_comissao:'F',
            loading_save: false,
            dono_name: '',
            msgErro: '',
            loading_search_dono: false,
            donos: [],
            dono_selecionado: {},
            show_autocomplete_dono: false,
            categorias: {},
            loading_cep: false,
            categorias_selecionadas: {},
            categoria: '',
            telefones: [''],
            tipo_servico:'e'


        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();

        form.append('imagem', this.state.imagem);

        form.append('nome', this.state.nome);
        form.append('cnpj', this.state.cnpj);
        form.append('cep', this.state.cep);
        form.append('rua', this.state.rua);
        // form.append('tipo_comissao', this.state.tipo_comissao);
        form.append('numero', this.state.numero);
        form.append('bairro', this.state.bairro);
        form.append('cidade', this.state.cidade);
        form.append('estado', this.state.estado);

        form.append('latitude', this.state.latitude);
        form.append('longitude', this.state.longitude);
        form.append('complemento', this.state.complemento);
        form.append('razao_social', this.state.razao_social);

        if(Object.keys(this.state.dono_selecionado).length != 0){
            form.append('dono', Object.keys(this.state.dono_selecionado)[0]);
        }
        for (let i = 0; i < Object.keys(this.state.categorias_selecionadas).length; i++) {

            form.append('categorias[]', Object.keys(this.state.categorias_selecionadas)[i]);
        }
        for (let i = 0; i < this.state.telefones.length; i++) {

            form.append('telefones[]', this.state.telefones[i]);
        }
        form.append('preco_km', this.state.preco_km.replace(/\./g, '').replace(',', '.').replace('R', '').replace('$', '').replace(' ', ''));
        form.append('comissao', this.state.comissao);
        form.append('comissao_hubi', this.state.comissao_hubi);

        form.append('tipo_servico', this.state.tipo_servico);

        fetch(`${URL}api/salvar_empresa`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_save: false, msgErro: erro },()=>{
                    window.scrollTo(0,0);

                    });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Empresas');

        // this.get_roles();
        this.get_categorias();
    }

    get_categorias() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_categorias_empresa`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let categorias = {}
                    for (let i = 0; i < resp.categorias.length; i++) {
                        categorias[resp.categorias[i].id] = resp.categorias[i];
                    }
                    this.setState({ loading_screen: false, categorias: categorias, categoria: '' });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    change_categoria(event) {


        let categorias_selecionadas = { ...this.state.categorias_selecionadas };
        if (categorias_selecionadas[event.target.value] == undefined) {
            categorias_selecionadas[event.target.value] = this.state.categorias[event.target.value];
        }
        this.setState({ categorias_selecionadas: categorias_selecionadas })
    }

    select_dono(item) {
        let users = {};
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ dono_name: '', show_autocomplete_dono: false, dono_selecionado: users })
    }

    buscar_endereco(cep) {
        this.setState({ loading_cep: true });
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {

            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    rua: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: '',
                    loading_cep: false

                }, () => {
                    document.getElementById('numero').focus();
                    this.get_lat_lng();
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?', loading_cep: false });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`, loading_cep: false });
            });


    }

    get_lat_lng() {
        let address = `${this.state.rua},+${this.state.numero},+${this.state.bairro},+${this.state.cidade},+${this.state.estado},+Brasil`
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE&components=country=Brazil|region=BR`, {
            method: 'GET',
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.results.length > 0) {
                    this.setState({ latitude: resp.results[0].geometry.location.lat, longitude: resp.results[0].geometry.location.lng })
                }



            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    get_dono_search(search) {

        if (this.state.loading_search_dono == false) {
            this.setState({ loading_search_dono: true });
        }
        fetch(`${URL}api/donos_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_dono: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_dono: false, donos: resp.users });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_dono: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_dono: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar Empresa</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre uma empresa no seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Imagem <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" type="file" accept="image/*" onChange={(e) => {
                                                    let imagem = e.target.files[0];
                                                    this.setState({ imagem });
                                                    let imagem_path = '';
                                                    let reader = new FileReader()
                                                    reader.readAsDataURL(e.target.files[0])
                                                    reader.onload = () => {
                                                        imagem_path = reader.result;
                                                        this.setState({ imagem_path });
                                                    };
                                                    reader.onerror = function (error) {
                                                        console.log('Error: ', error);
                                                    }
                                                    e.target.value='';
                                                }} />

                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.imagem_path != '' &&
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-6 col-md-3">
                                                    <div>
                                                        <img src={this.state.imagem_path} style={{ height: 100, width: 100, objectFit: "contain", border: '1px solid lightgrey' }} />
                                                        <p><a onClick={() => {

                                                            this.setState({ imagem: '', imagem_path: '' });
                                                        }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span style={{ marginRight: 5 }}>Remover</span><i className="fas fa-times"></i></a></p>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                        <hr />


                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.nome} placeholder="Nome da empresa" type="text" onChange={(e) => this.setState({ nome: e.target.value })} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Razão Social <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.razao_social} placeholder="Razão Social" type="text" onChange={(e) => this.setState({ razao_social: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Categorias <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.categoria} className="custom-select" id="categorias" onChange={this.change_categoria.bind(this)}>
                                                    <option value={''}>Selecione uma ou mais categorias</option>

                                                    {Object.values(this.state.categorias).map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        {Object.values(this.state.categorias_selecionadas).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Categorias Selecionadas:</label>

                                            {Object.values(this.state.categorias_selecionadas).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{item.nome}</span>
                                                    <button onClick={() => {
                                                        let categorias_selecionadas = { ...this.state.categorias_selecionadas };
                                                        delete (categorias_selecionadas[item.id]);
                                                        this.setState({ categorias_selecionadas: categorias_selecionadas });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                            className="ti-trash" style={{ color: 'red' }}></i></button>
                                                </div>
                                            ))}

                                        </div>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Dono da Empresa</label>
                                            <div className="col-sm-12">
                                                <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_dono: true })} className="form-control" value={this.state.dono_name} placeholder="Nome do dono da empresa" type="text" onChange={(e) => { this.get_dono_search(e.target.value); this.setState({ dono_name: e.target.value }) }} />
                                                {this.state.show_autocomplete_dono == true && this.state.dono_name != '' && <div className="overflow-auto" style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto' }}>
                                                    {this.state.loading_search_dono == false && this.state.donos.map((item, id) => (
                                                        <div key={id} onClick={() => this.select_dono(item)} className="opcao_autocomplete">
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                    {this.state.loading_search_dono == false && this.state.donos.length == 0 &&
                                                        <div className="opcao_autocomplete">
                                                            Nenhum usuário tipo empresa encontrado com este termo
                                                        </div>
                                                    }
                                                    {this.state.loading_search_dono == true &&
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <div className="spinner-border text-primary" role="status"  >
                                                                <span className="sr-only">Loading...</span>
                                                            </div></div>}
                                                </div>}
                                            </div>


                                        </div>

                                        {Object.values(this.state.dono_selecionado).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Dono Selecionado:</label>

                                            {Object.values(this.state.dono_selecionado).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{item.name}</span>
                                                    <button onClick={() => {
                                                        let users = { ...this.state.dono_selecionado };
                                                        delete (users[item.id]);
                                                        this.setState({ dono_selecionado: users });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                            className="ti-trash" style={{ color: 'red' }}></i></button>
                                                </div>
                                            ))}

                                        </div>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CNPJ <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.cnpj} placeholder="CNPJ da empresa" type="text" onChange={(e) => this.setState({ cnpj: maskCnpj(e.target.value) })} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Telefone(s) <b style={{ color: 'red' }}>*</b></label>
                                            
                                            {this.state.telefones.map((item,id)=>(
                                                <div className="col-sm-12 mb-3" key={id}>
                                                <input className="form-control" value={item} placeholder="Telefone da empresa" type="text" onChange={(e) => {
                                                    let telefones = [...this.state.telefones]
                                                    telefones[id]=maskTelefone(e.target.value);
                                                    this.setState({ telefones  });
                                                }} />

                                            </div>
                                            ))}
                                           
                                            <div className="col-12  d-flex justify-content-end justify-content-sm-end "
                                                style={{ marginBottom: '0.5rem' }}>
                                                <button type="button" onClick={() => {
                                                    let telefones = [...this.state.telefones]
                                                    telefones.push('');
                                                    this.setState({ telefones  });
                                                }} className="btn btn-primary  waves-effect waves-light mr-2"
                                                    style={{ backgorundColor: '#f2f2f2' }}>Adicionar +1</button>
                                                <button hidden={this.state.telefones.length==1} type="button" onClick={() => {
                                                    let telefones = [...this.state.telefones]
                                                    if(telefones.length>1){
                                                        telefones.pop();
                                                    }
                                                    console.log(telefones);
                                                    this.setState({ telefones  });
                                                }} className="btn btn-danger  waves-effect waves-light"
                                                    style={this.state.telefones.length==1 ? { backgroundColor: 'grey',borderColor:'grey' }:{}}>Remover -1</button>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Tipo de serviço <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <select value={this.state.tipo_servico} className="custom-select"  onChange={(e)=>{this.setState({tipo_servico:e.target.value})}}>

                                                    <option value={'e'}>Entrega</option>
                                                    <option value={'r'}>Retirada</option>
                                                    <option value={'re'}>Entrega e Retirada</option>

                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row" hidden={!this.state.tipo_servico.includes('e')}>
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Preço por Km <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.preco_km} placeholder="Preço por Km" type="text" onChange={(e) => this.setState({ preco_km: maskPrice(e.target.value) })} />

                                            </div>
                                        </div>
                                        {/* <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Tipo de comissão <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                <select value={this.state.tipo_comissao} className="custom-select"  onChange={(e)=>{this.setState({tipo_comissao:e.target.value})}}>

                                                    <option value={'F'}>Comissão fixa</option>
                                                    <option value={'P'}>Comissão por produto</option>

                                                </select>
                                            </div>
                                        </div> */}
                                        <div  className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Valor da Comissão do Vendedor (Em porcentagem. Ex: 10%) <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.comissao} placeholder="Valor da Comissão do Vendedor (Em porcentagem. Ex: 10%)" type="text" onChange={(e) => this.setState({ comissao: maskPercent(e.target.value) })} />

                                            </div>
                                        </div>
                                        <div  className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Valor da Comissão da Plataforma HUBI (Em porcentagem. Ex: 10%) <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.comissao_hubi} placeholder="Valor da Comissão da Plataforma HUBI (Em porcentagem. Ex: 10%)" type="text" onChange={(e) => this.setState({ comissao_hubi: maskPercent(e.target.value) })} />

                                            </div>
                                        </div>
                                        <hr />
                                        <p style={{ fontWeight: '600', color: 'black', fontSize: 16 }}>Endereço da empresa:</p>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>CEP <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12" style={{ position: 'relative' }}>
                                                <input disabled={this.state.loading_cep} className="form-control" value={this.state.cep} placeholder="CEP" type="text" onChange={(e) => {
                                                    this.setState({ cep: maskCep(e.target.value) });
                                                    let text = e.target.value.replace('-', '');
                                                    if (text.length == 8) {
                                                        this.buscar_endereco(text);
                                                    }
                                                }} />
                                                {this.state.loading_cep == true && <div style={{ position: 'absolute', top: 0, right: 10 }}>

                                                    <div style={{ height: 15, width: 15, marginLeft: 10 }} className="spinner-border text-primary" role="status"  >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>}

                                            </div>


                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Rua <b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <input disabled={this.state.loading_cep} className="form-control" value={this.state.rua} placeholder="Rua" type="text" onChange={(e) => this.setState({ rua: e.target.value }, () => this.get_lat_lng())} />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6">

                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Número <b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <input disabled={this.state.loading_cep} className="form-control" value={this.state.numero} placeholder="Número" type="text" id="numero" onChange={(e) => this.setState({ numero: e.target.value }, () => this.get_lat_lng())} />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Complemento</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.complemento} placeholder="Complemento" type="text" onChange={(e) => this.setState({ complemento: e.target.value })} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Bairro <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input disabled={this.state.loading_cep} className="form-control" value={this.state.bairro} placeholder="Bairro" type="text" onChange={(e) => this.setState({ bairro: e.target.value }, () => this.get_lat_lng())} />

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Cidade <b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <input disabled={this.state.loading_cep} className="form-control" value={this.state.cidade} placeholder="Cidade" type="text" onChange={(e) => this.setState({ cidade: e.target.value }, () => this.get_lat_lng())} />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6">

                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Estado (Sigla) <b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <input disabled={this.state.loading_cep} className="form-control" value={this.state.estado} placeholder="Estado (Sigla)" type="text" onChange={(e) => this.setState({ estado: e.target.value }, () => this.get_lat_lng())} />

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div style={{ height: 300, overflow: 'hidden' }}>

                                                    <Map
                                                        google={window.google}
                                                        style={{ height: 300, position: "relative" }}
                                                        center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                                        className="map2"
                                                        zoom={14}>
                                                        {this.state.latitude != '' && <Marker
                                                            title={'The marker`s title will appear as a tooltip.'}
                                                            name={'SOMA'}
                                                            position={{ lat: this.state.latitude, lng: this.state.longitude }} />}

                                                    </Map>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/empresas"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar empresa</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(GoogleApiWrapper({ apiKey: 'AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE' })(CriarEmpresa));


