
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { maskCnpj, maskCep, maskTelefone, maskPrice, maskPercent } from '../../Auxiliar/Masks';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class CriarAviso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/avisos',
            loading_screen: false,
            titulo: '',
            mensagem: '',
            loading_save: false,
            vendedor_name: '',
            msgErro: '',
            loading_search_vendedor: false,
            vendedores: [],
            vendedor_selecionado: {},
            show_autocomplete_vendedor: false,

            empresas_selecionadas: {},
            empresa: '',
            empresas: {},

        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();

        form.append('titulo', this.state.titulo);

        form.append('mensagem', this.state.mensagem);
        if (Object.keys(this.state.vendedor_selecionado).length != 0) {
            form.append('vendedor', Object.keys(this.state.vendedor_selecionado)[0]);
        }
        if (Object.keys(this.state.empresas_selecionadas).length != 0) {
            form.append('empresa', Object.keys(this.state.empresas_selecionadas)[0]);
        }

        fetch(`${URL}api/meus_avisos/salvar_aviso`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_save: false, msgErro: erro }, () => {
                        window.scrollTo(0, 0);

                    });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Notificações');

        // this.get_roles();
        this.get_empresas_all();
    }


    get_empresas_all() {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/minha_empresa/get_empresas_all`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let empresas = {}
                    for (let i = 0; i < resp.empresas.length; i++) {
                        empresas[resp.empresas[i].id] = resp.empresas[i];
                    }
                    this.setState({ empresas: empresas, loading_screen: false });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    change_empresa(event) {
        let users = {  };
        users[event.target.value] = this.state.empresas[event.target.value];
        console.log(users);
        this.setState({ empresas_selecionadas: users })
    }

    select_vendedor(item) {
        let users = {};
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ vendedor_name: '', show_autocomplete_vendedor: false, vendedor_selecionado: users })
    }


    get_vendedor_search(search) {

        if (this.state.loading_search_vendedor == false) {
            this.setState({ loading_search_vendedor: true });
        }
        fetch(`${URL}api/vendedor_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_vendedor: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_vendedor: false, vendedores: resp.users });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_vendedor: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_vendedor: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Nova Notificação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Envie uma Notificação para um vendedor</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Empresa <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.empresa} className="custom-select" id="empresas" onChange={this.change_empresa.bind(this)}>
                                                    <option value={''}>Selecione uma ou mais empresas</option>

                                                    {Object.values(this.state.empresas).map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        {Object.values(this.state.empresas_selecionadas).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Empresa Selecionada:</label>

                                            {Object.values(this.state.empresas_selecionadas).map((item, id) => (
                                                <div key={id} className="col-sm-12 col-12 col-md-12 col-lg-12" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{item.nome}</span>
                                                    <button onClick={() => {
                                                        let users = { ...this.state.empresas_selecionadas };
                                                        delete (users[item.id]);
                                                        this.setState({ empresas_selecionadas: users });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                            className="ti-trash" style={{ color: 'red' }}></i></button>
                                                </div>
                                            ))}

                                        </div>}


                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Vendedor <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_vendedor: true })} className="form-control" value={this.state.vendedor_name} placeholder="Nome do vendedor" type="text" onChange={(e) => { this.get_vendedor_search(e.target.value); this.setState({ vendedor_name: e.target.value }) }} />
                                                {this.state.show_autocomplete_vendedor == true && this.state.vendedor_name != '' && <div className="overflow-auto" style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto' }}>
                                                    {this.state.loading_search_vendedor == false && this.state.vendedores.map((item, id) => (
                                                        <div key={id} onClick={() => this.select_vendedor(item)} className="opcao_autocomplete">
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                    {this.state.loading_search_vendedor == false && this.state.vendedores.length == 0 &&
                                                        <div className="opcao_autocomplete">
                                                            Nenhum vendedor encontrado com este termo
                                                        </div>
                                                    }
                                                    {this.state.loading_search_vendedor == true &&
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <div className="spinner-border text-primary" role="status"  >
                                                                <span className="sr-only">Loading...</span>
                                                            </div></div>}
                                                </div>}
                                            </div>


                                        </div>

                                        {Object.values(this.state.vendedor_selecionado).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Vendedor Selecionado:</label>

                                            {Object.values(this.state.vendedor_selecionado).map((item, id) => (
                                                <div key={id} className="col-sm-12 col-12 col-md-12 col-lg-12" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{item.name}</span>
                                                    <button onClick={() => {
                                                        let users = { ...this.state.vendedor_selecionado };
                                                        delete (users[item.id]);
                                                        this.setState({ vendedor_selecionado: users });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                            className="ti-trash" style={{ color: 'red' }}></i></button>
                                                </div>
                                            ))}

                                        </div>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Título <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.titulo} placeholder="Título do aviso" type="text" onChange={(e) => this.setState({ titulo: e.target.value })} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Mensagem <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" value={this.state.mensagem} placeholder="Mensagem" type="text" onChange={(e) => this.setState({ mensagem: e.target.value })}></textarea>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/avisos"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Enviar Notificação</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(CriarAviso);


