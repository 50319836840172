import React from 'react';
import ProfileMenu from './ProfileMenu';
import { URL } from '../variables';
import { connect } from 'react-redux';
import logo from '../assets/images/hubi1.png';
import { Link } from 'react-router-dom';

class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="topbar">

                <div className="topbar-left" style={{ background: '#ececf1',display:'block' }}>
                   <Link to='/' className="logo">
                        <span>
                            <img src={logo} alt="" height='60' />
                        </span>
                        <i>
                            <img style={{width:50,height:50,objectFit:'contain'}} className="img-fluid" src={logo} alt="" height="100" />
                        </i>
                    </Link>
                </div>
                <nav className="navbar-custom">
                    <ul className="navbar-right d-flex list-inline float-right mb-0">
                        <ProfileMenu />


                    </ul>



                </nav>
            </div>

        );
    }


}

const mapsStateToProps = (state) => (
    {
        faculdade: state.AppReducer.faculdade
    }
);

export default connect(mapsStateToProps, {})(Header);