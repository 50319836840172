
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';

class Usuarios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            users: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            user_to_delete: '',
            roles: {},
            role_id: 'todos',
            vendedor: {},
            show_warning_vendedor: false,
            show_sucesso: false,
            msgSucesso: '',
            search: '',
            filter_aprovacao:'all'
        };
    }






    componentDidMount() {
        this.props.mudarTitle('Usuários');
        console.log(this.props.history);
        if (this.props.location.search.replace('?role=', '') != '') {
            this.setState({ role_id: this.props.location.search.replace('?role=', '') }, () => {
                this.get_users(this.state.page, this.state.role_id);

            });
        }
        else {
            this.props.history.push({
                pathname: '/usuarios',
                search: "?" + new URLSearchParams({ role: this.state.role_id }).toString()
            });
            this.get_users(this.state.page, this.state.role_id);

        }
    }

    get_users(page, role) {
        console.log('Page: ' + page);
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_users?page=${page}&role=${role}&search=${this.state.search}&filter_aprovacao=${this.state.filter_aprovacao}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ roles: resp.roles, users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    delete_usuario(id) {

        fetch(`${URL}api/delete_usuario/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_users(this.state.page, this.state.role_id);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    status_vendedor(id) {

        fetch(`${URL}api/status_vendedor/${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning_vendedor: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning_vendedor: false, show_sucesso: true, msgSucesso: 'Vendedor ' + this.state.vendedor.name + (this.state.vendedor.aprovado == false ? ' aprovado' : ' bloqueado'), vendedor: {} });
                    this.get_users(this.state.page, this.state.role_id);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    change_roles(event) {
        this.setState({ role_id: event.target.value }, () => {
            this.props.history.push({
                pathname: '/usuarios',
                search: "?" + new URLSearchParams({ role: this.state.role_id }).toString()
            });
            this.get_users(1, this.state.role_id);
        });
    }



    render() {
        console.log(this.state.total_pages);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="float-right">
                                <Link to="/"><span
                                    className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                            </div>
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de Usuários</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os usuários do seu sistema</p>
                            <br />
                            <br />

                            {/* <hr /> */}

                            {<div>
                                <div className="row mb-2">


                                    <div className="col-12 col-md-6">
                                        <form className="app-search" style={{ width: '100%' }} onSubmit={(e) => {
                                            this.setState({ page: 1 }, () => {
                                                this.get_users(1, this.state.role_id)
                                            });
                                            e.preventDefault();
                                        }}>
                                            <div className="form-group mb-0" style={{ width: '100%' }}>
                                                <input value={this.state.search} name="search" placeholder="Pesquisar por Nome ou Função..." className="form-control" style={{ width: '100%' }} onChange={(e) => {
                                                    this.setState({ page: 1, search: e.target.value }, () => {
                                                        this.get_users(1, this.state.role_id)
                                                    });
                                                    // this.get_banners(1, this.props.loja_id, e.target.value, true)
                                                }} />
                                                {/* <button type="submit"><i className="fa fa-search"></i></button> */}

                                            </div>
                                        </form>
                                        <select value={this.state.filter_aprovacao} className="custom-select" id="empresas" onChange={(e)=>{
                                            this.setState({filter_aprovacao:e.target.value},()=>{
                                                this.get_users(1, this.state.role_id)
                                            });
                                        }}>
                                            <option value={'all'}>Todos</option>
                                            <option value={'pendentes'}>Precisando de aprovação</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex justify-content-end">
                                        <Link to="/usuarios/criar"><span
                                            className="btn btn-primary btn-lg waves-effect waves-light" style={{ height: '45px' }}>Adicionar usuário</span></Link>
                                    </div>
                                </div>
                                <div className="table-rep-plugin">
                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                        <table id="tech-companies-1" className="table table-striped">
                                            <thead>
                                                <tr>

                                                    <th>Nome</th>
                                                    <th>Email</th>
                                                    <th  >Função</th>
                                                    <th  >Ações</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loading_screen == false && this.state.users.map((item, id) => (

                                                    <tr key={id}>

                                                        <th>{item.name}</th>
                                                        <td>{item.email}</td>

                                                        <td>{item.role}</td>
                                                        <td>
                                                            <ReactTooltip />

                                                            {item.role_name == 'vendedor' && item.aprovado == false && <button data-tip="Aceitar Vendedor" onClick={() => {
                                                                this.setState({ show_warning_vendedor: true, vendedor: item });
                                                            }}
                                                                className="btn btn-success btn-outline btn-circle m-r-5"><i
                                                                    className="mdi mdi-check"></i></button>}
                                                            {item.role_name == 'vendedor' && item.aprovado == true && <button data-tip="Recusar Vendedor" onClick={() => {
                                                                this.setState({ show_warning_vendedor: true, vendedor: item });
                                                            }}
                                                                className="btn btn-danger btn-outline btn-circle m-r-5"><i
                                                                    className="mdi mdi-close"></i></button>}
                                                            <Link data-tip="Editar Usuário" to={'/usuarios/editar/' + item.id}><span className="btn btn-info btn-outline btn-circle m-r-5"
                                                                data-toggle="tooltip"
                                                            ><i className="ti-pencil-alt"></i></span></Link>
                                                            <button data-tip="Excluir Usuário" onClick={() => {
                                                                this.setState({ show_warning: true, user_to_delete: item.id });
                                                            }}
                                                                className="destroy_it btn btn-danger btn-outline btn-circle m-r-5"><i
                                                                    className="ti-trash"></i></button>
                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.state.loading_screen == false && this.state.users.length == 0 && <div className="row w-100">
                                    <div className="col-12">
                                        <p style={{ textAlign: 'center' }}>Nenhum usuário cadastrado ou encontrado</p>

                                    </div>
                                </div>}
                                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <ReactPaginate
                                            previousLabel={'Anterior'}
                                            nextLabel={'Próxima'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.total_pages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick.bind(this)}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            initialPage={this.state.page - 1}
                                        />
                                    </div>
                                </div>



                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}


                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => {
                                    this.delete_usuario(this.state.user_to_delete);
                                }}
                                onCancel={() => {
                                    this.setState({ show_warning: false });
                                }}
                                show={this.state.show_warning}
                                confirmBtnText='Sim, desejo apagar'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                ao deletar este usuário ele não estará mais disponível no sistema

                                </SweetAlert>
                            <SweetAlert
                                danger
                                title={"Erro"}
                                onConfirm={() => {
                                    this.setState({ show_erro: false });
                                }}
                                show={this.state.show_erro}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="primary"

                            >
                                {this.state.msgErro}

                            </SweetAlert>


                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => {
                                    this.status_vendedor(this.state.vendedor.id);
                                }}
                                onCancel={() => {
                                    this.setState({ show_warning_vendedor: false });
                                }}
                                show={this.state.show_warning_vendedor}
                                confirmBtnText={this.state.vendedor.aprovado == false ? 'Sim, desejo aprovar' : 'Sim, desejo bloquear'}
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                {this.state.vendedor.aprovado == false ? 'ao aprovar o vendedor ' + this.state.vendedor.name + ', ele poderá acessar todas as funções do aplicativo' : 'ao bloquear o vendedor ' + this.state.vendedor.name + ', ele perderá o acesso a todas as funções do aplicativo'}

                            </SweetAlert>


                            <SweetAlert
                                success
                                title={"Sucesso"}
                                onConfirm={() => {
                                    this.setState({ show_sucesso: false });
                                }}
                                show={this.state.show_sucesso}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="primary"

                            >
                                {this.state.msgSucesso}

                            </SweetAlert>

                        </div>

                    </div>
                </div>
            </div>
        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_users(page.selected + 1, this.state.role_id);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(Usuarios);


